import React from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetResellerInfoQuery } from "./resellerInfoApiSlice"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import IsLoading from "../../../components/IsLoading"
import ISPDetails from "./ISPDetails"
import PaymentPlatforms from "./PaymentPlatforms"

const ResellerInfo = () => {
  const {
    data: IspDetails,
    error,
    isLoading,
    refetch,
  } = useGetResellerInfoQuery()

  let content
  if (isLoading) {
    content = <IsLoading />
  } else if (error) {
    content = error?.message || "Error Occured"
  } else {
    content = (
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="isp"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="isp" title="ISP Details">
              <ISPDetails data={IspDetails?.data || {}} />
            </Tab>
            <Tab eventKey="platforms" title="Payment Platforms">
              <PaymentPlatforms platforms={IspDetails?.data.payment_platforms || []}  />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Dashboard title={"ISP Info"}>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default ResellerInfo
