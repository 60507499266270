import React from "react"
import { Table } from "react-bootstrap"
import { formatDate } from "../../../../helpers"

const CustomerDetails = ({ customer }) => {
  return (
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{customer?.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{customer?.last_name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{customer?.email}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{customer?.username}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{customer?.phone_number}</td>
            </tr>
            <tr>
              <td>Joined On</td>
              <td>{formatDate(customer?.inserted_at)}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{customer?.status}</td>
            </tr>
          </tbody>
        </Table>
  )
}

export default CustomerDetails
