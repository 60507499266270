import React from "react"
import ReactApexChart from "react-apexcharts"

import { Card } from "react-bootstrap"

const IncomeInLast7Days = ({ data }) => {
  const incomeIndays = (data || []).reduce(
    (accum, current) => ({ ...accum, [current.date]: Number(current.amount) }),
    {}
  );
  const cxData = (data || []).map((item) => {
    if (Object.keys(incomeIndays).includes(item.date)) {
      return incomeIndays[item.date]
    } else {
      return 0
    }
  });

  const label  = (data || []).map((item) => new Date(item.date).toLocaleString());


  // Area Chart
  const seriesArea = [{
    name: "KES",
    data: cxData
  }];

  const optionArea = {
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight",
      width: 2
    },
    labels: label,
    xaxis: {
      labels: {
        formatter: function(value, timestamp) {
          return new Date(value).toLocaleDateString()
        },
        type: "datetime",
      }
      
    },
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: "left"
    }
  };

  return (
    <Card className="ml-5">
      <Card.Header>
        <Card.Title as="h6">
          Income In the Last 7 Days (value in KES)
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-2">
      <ReactApexChart series={seriesArea} options={optionArea} type="area" height={450} />
      </Card.Body>
    </Card>
  )
}

export default IncomeInLast7Days
