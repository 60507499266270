import React from "react"
import { Table, Dropdown } from "react-bootstrap"
import { formatDate, formatMoney } from "../../helpers"

const PendingBill = ({ data }) => {
  const content = Array.isArray(data)
    ? data.filter(d => d.status == 'pending').map((d, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{d.status}</td>
            <td>{formatMoney(d.total_hotspot_collected)}</td>
            <td>{formatMoney(d.total_ppoe_collected)}</td>
            <td>{d.ppoe_renewed_customers}</td>
            <td>{formatMoney(d.hotspot_billing_amount)}</td>
            <td>{formatMoney(d.ppoe_billing_amount)}</td>
            <td>{formatMoney(d.total_billing_amount)}</td>
            <td>{d.currency}</td>
            <td>{formatDate(d.from_date, true)}</td>
            <td>{formatDate(d.to_date, true)}</td>
            <td>{formatDate(d.created_at, true)}</td>
            <td>{formatMoney(d.discount)}</td>
            <td>{d.payment_method}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" size="sm">
                  Action
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Pay</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )
      })
    : []

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>status</th>
            <th>Hotspot Collected</th>
            <th>PPOE Collected</th>
            <th>PPoe Renewed Customers</th>
            <th>Hotspot</th>
            <th>PPOE</th>
            <th>Total Billing</th>
            <th>Currency</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Created on</th>
            <th>Discount</th>
            <th>Payment Method</th>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    </>
  )
}

export default PendingBill
