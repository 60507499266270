import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { useGetPppPackagesQuery } from "../../../../app/api/sharedApiSlice"
import { useGetZonesQuery } from "../../../Configuration/Zones/zoneApiSlice"
import Dashboard from "../../../../components/Dashboard"
import {
  useGetPPPUserQuery} from "../pppUsersApiSlice"
import CustomerDetails from "./CustomerDetails"
import SubscriptionList from "./SubscriptionList"
import Credentails from "./Credentials"
import Accounts from "./Accounts"
import IsLoading from "../../../../components/IsLoading"

export default function Profile() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const { data: plans } = useGetPppPackagesQuery()
  const { data: zones } = useGetZonesQuery()
  const [requireFetch, setRequireFetch] = useState(false)
  const {
    data: customerProfile,
    error: profileErrors,
    isLoading,
    refetch,
  } = useGetPPPUserQuery(routeParams.customerId)
  const {customer = {}, accounts = [], subscriptions = []} = customerProfile?.data || {}


  useEffect(()=> {
    refetch(routeParams.customerId)
  }, [requireFetch])


  const content = isLoading ? (
    <IsLoading />
  ) : (
    <Row>
      <Col>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Customer Details">
            <CustomerDetails customer={customer} />
          </Tab>
          <Tab eventKey="profile" title="Subscription Details">
            <SubscriptionList
              customer={customer}
              plans={plans}
              zones={zones}
              accounts={accounts}
              subscriptions={subscriptions}
              handleRefetch={setRequireFetch}
            />
          </Tab>

          <Tab eventKey="credentails" title="PPPoe Credentials">
            <Credentails subscriptions={subscriptions} />
          </Tab>
          <Tab eventKey="accounts" title="Accounts">
            <Accounts accounts={accounts} subscriptions={subscriptions} />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  )

  return (
    <>
      <Dashboard title={"Customer Info"}>{content}</Dashboard>
    </>
  )
}
