import toast from "react-hot-toast"

export const readDataFromLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    return {}
  }
}

export const formatime = (duration, unit) => {
  if (duration > 1) {
    return `${duration} ${unit}s`
  }
  return `${duration} ${unit}`
}

export const formatTimeInZ = (dateTime) => {
  // const dateTimeArr = dateTime.split('T');
  // const timeArray = dateTimeArr[1].split('.');
  // const time = timeArray[0]
  const date = new Date(dateTime).toLocaleString()
  return date
}

export const formatMoney = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  }).format(amount)
}

export const notify = (message, isError) => {
  if (isError) {
    toast.error(message)
    return
  }
  toast.success(message)
  return
}

export const formatErrors = (error) => {
  if (error?.message) {
    return error.message
  }
  return formatFormErrors(error)
}

export const formatFormErrors = (errors) => {
  if (errors === null) return null
  if (!isObject(errors)) return "Something bad happened. Try again"

  const messages = []
  for (const key in errors) {
    if (Array.isArray(errors[key])) {
      let errorMessage = Object.values(errors[key]).join(", ") // Join all error messages
      errorMessage = `${key}: ${errorMessage}`
      messages.push(errorMessage)
    } else {
      let errorMessage = errors[key]
      errorMessage = `${key}: ${errorMessage}`
      messages.push(errorMessage)
    }
  }

  return messages
}

export const formatDate = (datetime, onlyDate = false) => {
  if (datetime == null) return
  // Check if the datetime string already ends with 'Z'
  const date = datetime.endsWith('Z') ? new Date(datetime) : new Date(datetime + 'Z')
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  if(onlyDate) {
    return `${year}-${month}-${day}`
  }

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

}

export const formatNumber = (number) => {
  return Number(number).toLocaleString()
}

const isObject = (input) => {
  return input !== null && typeof input === "object"
}
