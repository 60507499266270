import React from "react"
import CustomDialog from "../../../components/CustomDialog"
import { Form, Card, Row, Col, Alert } from "react-bootstrap"

const HotspotForm = ({
  show,
  title,
  handleCloseDialog,
  formik,
  isLoading,
  errors,
  routers,
}) => {
  const routerOptions = (routers?.data || []).map((router) => {
    return (
      <option key={router.router_id} value={router.router_id}>
        {`${router.description} - ${router.nasname}`}
      </option>
    )
  })
  return (
    <CustomDialog
      show={show}
      title={title}
      onCLose={handleCloseDialog}
      onSave={formik.handleSubmit}
      isLoading={isLoading}
      size={"lg"}
    >
      <Form method="post" onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                {errors && errors?.message && (
                  <Alert variant="danger">{errors?.message}</Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Router</Form.Label>
                  <Form.Select
                    value={formik.values.router_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="router_id"
                  >
                    <option>Select Router</option>
                    {routerOptions}
                  </Form.Select>
                  {formik.errors.router_id && formik.touched.router_id && (
                    <Form.Text className="text-danger">
                      {formik.errors.router_id}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Interface/Bridge Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Bridge Name"
                    value={formik.values.bridge_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="bridge_name"
                  />
                  {formik.errors.bridge_name && formik.touched.bridge_name && (
                    <Form.Text className="text-danger">
                      {formik.errors.bridge_name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Hotspot Name (MBps)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hotspot Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <Form.Text className="text-danger">
                      {formik.errors.name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Landmark"
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="landmark"
                  />
                  {formik.errors.landmark && formik.touched.landmark && (
                    <Form.Text className="text-danger">
                      {formik.errors.landmark}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hotspot Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                  />
                  {formik.errors.description && formik.touched.description && (
                    <Form.Text className="text-danger">
                      {formik.errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </CustomDialog>
  )
}

export default HotspotForm
