import { apiSlice } from "../../../app/api/apiSlice";

export const zoneAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getZones: builder.query({
            query: ()=> ({
                url: '/api/isp/zones',
            })
        }),
        getCountries: builder.query({
            query: ()=> ({
                url: '/api/isp/countries',
            })
        }),
        createZone: builder.mutation({
            query: zone => ({
                url: '/api/isp/zones',
                method: 'POST',
                body: {
                    params: zone
                }
            })
        }),

        deleteZone: builder.mutation({
            query: zone_id => ({
                url:  `/api/isp/zones/${zone_id}`,
                method: 'DELETE'
            })
        }),

        updateZone: builder.mutation({
            query: zone => ({
                url: `/api/isp/zones/${zone.id}`,
                method: 'PUT',
                body: {
                    params: zone
                }
            })
        }),

    })
});

export const { 
    useGetZonesQuery, 
    useCreateZoneMutation, 
    useDeleteZoneMutation,
    useUpdateZoneMutation,
    useGetCountriesQuery
} =  zoneAPiSlice;