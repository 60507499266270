import React, { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"

import { Link } from "react-router-dom"
import Header from "../layouts/Header"

export default function Dashboard({ title, children }) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : ""
  const [skin, setSkin] = useState(currentSkin)

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white")

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary")
        btn.classList.remove("btn-white")
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      )

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary")
        btn.classList.add("btn-white")
      }
    }
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  return (
    <React.Fragment>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
          style: {
            background: "#506fd9",
            color: "#fff",
          },
          error: {
            style: {
              background: "#dc3545",
              color: "#fff",
            },
          },
        }}
      />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 tw-min-h-[100px]">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
            <h4 className="main-title mb-0">{title}</h4>
          </div>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}
