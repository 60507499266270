import { apiSlice } from "../../../app/api/apiSlice";

export const routerAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRouters: builder.query({
            query: ()=> ({
                url: '/api/isp/routers',
            })
        }),
        createRouter: builder.mutation({
            query: router => ({
                url: '/api/isp/routers',
                method: 'POST',
                body: {
                    params: router
                }
            })
        }),

        deleteRouter: builder.mutation({
            query: router_id => ({
                url:  `/api/isp/routers/${router_id}`,
                method: 'DELETE'
            })
        }),

        updateRouter: builder.mutation({
            query: router => ({
                url: `/api/isp/routers/${router.id}`,
                method: 'PUT',
                body: {
                    params: router
                }
            })
        }),

    })
});

export const { 
    useGetRoutersQuery, 
    useCreateRouterMutation, 
    useUpdateRouterMutation,
    useDeleteRouterMutation
} =  routerAPiSlice;