import { apiSlice } from "../../../app/api/apiSlice"

export const dashboardAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generalDashboardStats: builder.query({
      query: (year) => {
        return { url: `/api/isp/hotspots/dashboard/general-stats?year=${year}` }
      },
    }),
    incomeInPast7Days: builder.query({
      query: () => {
        return { url: `/api/isp/hotspots/dashboard/income-in-last-7-days` }
      },
    }),
    incomePerMonth: builder.query({
      query: (year) => {
        return { url: `/api/isp/hotspots/dashboard/income-per-month?service=hotspot&year=${year}` }
      },
    }),
    incomePerPlan: builder.query({
      query: (year) => {
        return { url: `/api/isp/hotspots/dashboard/income-per-plan?service=hotspot&year=${year}` }
      },
    }),
  }),
})

export const { 
  useGeneralDashboardStatsQuery,
  useIncomeInPast7DaysQuery,
  useIncomePerMonthQuery,
  useIncomePerPlanQuery
} = dashboardAPiSlice
