import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import { 
  useCreateAccessPointMutation, 
  useGetAccessPointsQuery, 
  useDeleteAccessPointMutation,
  useUpdateAccessPointMutation
 } from "./accessPointApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { notify } from "../../../helpers"
import AccessPointForm from "./AccessPointForm"
import { formatDate } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "uuid", description: "ID" },
  { name: "name", description: "AP Name" },
  { name: "mac_address", description: "Mac Address" },
  { name: "type", description: "AP Type" },
  { name: "status", description: "Status" },
  { name: "last_seen", description: "Last Seen" },
  { name: "landmark", description: "Landmark" },
  { name: "description", description: "Description" },
  { name: "offline_after", description: "Offline Duration (Mins)" },
]

const AccessPoints = () => {
  // state
  const {
    data: access_points,
    error: apErrors,
    isLoading: isLoadingAccessPoints,
    refetch,
  } = useGetAccessPointsQuery()

  const [createAccessPoint, { isLoading: isCreating }] = useCreateAccessPointMutation()
  const [updateAccessPoint, { isLoading: isEditing }] = useUpdateAccessPointMutation()
  const [deleteAccessPoint, { isLoading: isDeleting }] = useDeleteAccessPointMutation()
  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Access Point")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    offline_after: "",
    mac_address: "",
    description: "",
    landmark: "",
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  // handlers
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Add Access Point")
    setFormErrors(null)
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }


  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addaccessPoint = async (values, resetForm) => {
    try {
      await createAccessPoint({...values, offline_after: Number(values.offline_after)}).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("AP added Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error?.data.errors || error?.data)
      notify("Failed. Please try again", true)
    }
  }

  const editAccessPoint = async (values, resetForm) => {
    try {
      await updateAccessPoint({ ...values, id: selected.id }).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("AP updated Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error?.data.errors || error?.data)
      notify("Failed. Please try again", true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if (action === "add") {
      await addaccessPoint(values, resetForm)
    } else {
      await editAccessPoint(values, resetForm)
    }
  }


  const handleDelete = async (row) => {
    try {
      await deleteAccessPoint(row.id).unwrap()
        setShow(false)
        refetch()
        notify('AP Deleted Successfully', false)
    } catch (error) {
      setFormErrors(error?.data.errors || error?.data)
        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Access Point")
    setFormErrors(null)
    const values  = {
        name: row.name,
        type: row.type,
        offline_after: row.offline_after,
        mac_address: row.mac_address,
        description: row.description,
        landmark: row.landmark
    }
    setInitialValues(values)
    setSelected(row)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]

  // on state changes
  useEffect(() => {
    if (isCreating || isEditing) {
      setInprogress(true)
    } else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])

  // format to render
  let count = 0
  const formatedData = (access_points?.data || []).map((ap) => {
    count++
    return {
      ...ap,
      last_seen: formatDate(ap.last_seen),
      count
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoadingAccessPoints}
          error={apErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Access Points"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Access Point{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <AccessPointForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
      />
    </>
  )
}

export default AccessPoints

// validation
const validationSchema = Yup.object({
  name: Yup.string().required("AP name is required"),
  type: Yup.string().required("AP type is required"),
  description: Yup.string().optional(),
  landmark: Yup.string().required("landmark name is required"),
  offline_after: Yup.number().required("offline after is required"),
  mac_address: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
      "Invalid MAC address format"
    )
    .required("MAC address is required"),
})
