import { apiSlice } from "../../../app/api/apiSlice"

export const activeUsersAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveUsers: builder.query({
      query: (params) => {
        let url = "/api/isp/hotspots/customers"
        if (params.pageSize) {
          url = url + `?page_size=${params.pageSize}`
        }
        if (params.pageNumber) {
          url = url + `&page_number=${params.pageNumber}`
        }
        if (params.phoneNumber) {
          url = url + `&phone_number=${params.phoneNumber}`
        }
        return {
          url,
        }
      },
    }),
    reactivateSubscription: builder.mutation({
      query: (params) => ({
        url: `/api/isp/hotspots//subscriptions/reactivate/${params.current_sub_id}`,
        method: "POST",
        body: {
          params: params,
        },
      }),
    }),

    updateAccountStatus: builder.mutation({
      query: (params) => ({
        url: "reseller/account/hotspot/update-status",
        method: "POST",
        body: {
          params: params,
        },
      }),
    }),
  }),
})

export const { 
    useGetActiveUsersQuery, 
    useUpdateAccountStatusMutation,
    useReactivateSubscriptionMutation
} =activeUsersAPiSlice
