import { apiSlice } from "../../../app/api/apiSlice"

export const accessPointAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccessPoints: builder.query({
      query: () => ({
        url: "/api/isp/access-points",
      }),
    }),
    createAccessPoint: builder.mutation({
      query: (accessPoint) => ({
        url: "/api/isp/access-points",
        method: "POST",
        body: { 
          params: accessPoint
         },
      }),
    }),

    deleteAccessPoint: builder.mutation({
      query: (ap_id) => ({
        url: `/api/isp/access-points/${ap_id}`,
        method: "DELETE"
      }),
    }),

    updateAccessPoint: builder.mutation({
      query: (accessPoint) => ({
        url: `/api/isp/access-points/${accessPoint.id}`,
        method: "PUT",
        body: {
          params: accessPoint
        },
      }),
    }),
  }),
})

export const {
 useGetAccessPointsQuery,
 useCreateAccessPointMutation,
 useDeleteAccessPointMutation,
 useUpdateAccessPointMutation
} = accessPointAPiSlice
