import React, { useState } from "react"
import { Form, Card, Col, Row } from "react-bootstrap"
import ErrorMessage from "../../../../components/ErrorMessage"

const SubscriptionForm = ({
  formik,
  error,
  clearErrors,
  plans,
  zones,
  action,
}) => {
  const [toggleCredentials, setToggleCredentials] = useState(false)
  const [toggleAccount, setToggleAccount] = useState(false)

  const plansOptions = (plans || []).map((plan) => {
    return (
      <option key={plan.id} value={plan.id}>
        {plan.name}
      </option>
    )
  })

  const zonesOptions = (zones || []).map((zone) => {
    return (
      <option key={zone.id} value={zone.id}>
        {zone.name}
      </option>
    )
  })

  const handleAccountChange = (e) => {
    const { name, checked } = e.target
    formik.setFieldValue(name, checked)
    if (checked && name === "use_external_account") {
      setToggleAccount(true)
      // Set username and password as required
      formik.setFieldTouched("ext_account_number", true)
      // Only set errors if the fields are empty
      if (!formik.values.ext_account_number) {
        formik.setFieldError("ext_account_number", "Account is required")
      }
      return
    } else {
      formik.setFieldTouched("ext_account_number", false)
      setToggleAccount(false)
      return
    }
  }

  const handleCredentialsChange = (e) => {
    const { name, checked } = e.target
    formik.setFieldValue(name, checked)
    if (checked && name === "credentials_exists") {
      setToggleCredentials(true)

      // Set username and password as required
      formik.setFieldTouched("username", true)
      formik.setFieldTouched("password", true)
      // Only set errors if the fields are empty
      if (!formik.values.username) {
        formik.setFieldError("username", "Username is required")
      }
      if (!formik.values.password) {
        formik.setFieldError("password", "Password is required")
      }
      return
    } else {
      formik.setFieldTouched("username", false)
      formik.setFieldTouched("password", false)
      setToggleCredentials(false)
      return
    }
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <ErrorMessage errors={error} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="planId">
              <Form.Label>Plan</Form.Label>
              <Form.Select
                value={formik.values.plan_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="plan_id"
              >
                <option>Select Plan</option>
                {plansOptions}
              </Form.Select>
              {formik.errors.plan_id && formik.touched.plan_id && (
                <Form.Text className="text-danger">
                  {formik.errors.plan_id}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="zoneId">
              <Form.Label>Zone</Form.Label>
              <Form.Select
                value={formik.values.zone_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="zone_id"
              >
                <option>Select Zone</option>
                {zonesOptions}
              </Form.Select>
              {formik.errors.zone_id && formik.touched.zone_id && (
                <Form.Text className="text-danger">
                  {formik.errors.zone_id}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Landmark</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                value={formik.values.landmark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="landmark"
              />
              {formik.errors.landmark && formik.touched.landmark && (
                <Form.Text className="text-danger">
                  {formik.errors.landmark}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>House Number/ Apartment</Form.Label>
              <Form.Control
                type="text"
                placeholder="House Number / Apartment"
                value={formik.values.house_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="house_number"
              />
              {formik.errors.house_number && formik.touched.house_number && (
                <Form.Text className="text-danger">
                  {formik.errors.house_number}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row
          className={action === "add_subscription" ? "tw-block" : "tw-hidden"}
        >
          <Col>
            <Form.Group className="mb-3" controlId="planId">
              <div className="tw-flex tw-mt-4">
                <Form.Check
                  className="tw-w-6"
                  type="checkbox"
                  checked={formik.values.credentials_exists}
                  onChange={handleCredentialsChange}
                  onBlur={formik.handleBlur}
                  name="credentials_exists"
                  id="credentials_exists"
                />
                <Form.Label className="tw-mr-4">
                  Do you have Router Credentials
                </Form.Label>
              </div>

              {formik.errors.credentials_exists &&
                formik.touched.credentials_exists && (
                  <Form.Text className="text-danger">
                    {formik.errors.credentials_exists}
                  </Form.Text>
                )}
            </Form.Group>
          </Col>
        </Row>
        <div
          className={
            toggleCredentials
              ? "tw-block"
              : "tw-hidden"
          }
        >
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>PPPoe Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="username"
                />
                {formik.errors.username && formik.touched.username && (
                  <Form.Text className="text-danger">
                    {formik.errors.username}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>PPPoe Password</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="password"
                />
                {formik.errors.password && formik.touched.password && (
                  <Form.Text className="text-danger">
                    {formik.errors.password}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div
          className={action === "add_subscription" ? "tw-block" : "tw-hidden"}
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="planId">
                <div className="tw-flex tw-mt-4">
                  <Form.Check
                    className="tw-w-6"
                    type="checkbox"
                    checked={formik.values.use_external_account}
                    onChange={handleAccountChange}
                    onBlur={formik.handleBlur}
                    name="use_external_account"
                    id="use_external_account"
                  />
                  <Form.Label className="tw-mr-4">
                    Do you have a dedicated payment account for this customer
                  </Form.Label>
                </div>

                {formik.errors.use_external_account &&
                  formik.touched.use_external_account && (
                    <Form.Text className="text-danger">
                      {formik.errors.use_external_account}
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div
          className={
            toggleAccount || formik.values.use_external_account
              ? "tw-block"
              : "tw-hidden"
          }
        >
          <Row
            className={action === "add_subscription" ? "tw-block" : "tw-hidden"}
          >
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Account Number"
                  value={formik.values.ext_account_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="ext_account_number"
                />
                {formik.errors.ext_account_number &&
                  formik.touched.ext_account_number && (
                    <Form.Text className="text-danger">
                      {formik.errors.ext_account_number}
                    </Form.Text>
                  )}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}

export default SubscriptionForm
