import { apiSlice } from "../../app/api/apiSlice";

export const billingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBillingSummary: builder.query({
            query: () => `/reseller/billing/summary`,
        }),
        getBillingHistory: builder.query({
            query: () => `/api/isp/billing-history`,
        }),

    })
});

export const { useGetBillingHistoryQuery, useGetBillingSummaryQuery } =  billingApiSlice;