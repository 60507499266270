import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import {
  useGetActiveUsersQuery,
  useReactivateSubscriptionMutation,
} from "./activeUsersApiSlice"
import { Col, Row, Card } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { formatDate } from "../../../helpers"
import FilterByPhone from "../../../components/FilterByPhone"
import { notify } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "username", description: "Device" },
  { name: "phone_number", description: "Phone Number" },
  { name: "status", description: "Sub Status" },
  { name: "network_status", description: "Network Status" },
  { name: "time_used", description: "Time Used" },
  { name: "total_data", description: "Total Data" },
  { name: "expires_at", description: "Expires In" },
  { name: "activated_at", description: "Activated On" },
  { name: "last_seen", description: "Last Seen on" },
  { name: "hotspot_name", description: "Hotspot Name" },
  { name: "plan_name", description: "Plan Name" },
  { name: "assigned_ip", description: "IP Add" },
]

const ActiveUsers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [filteredPhone, setFilterPhone] = useState(null)
  const [reactivateSubscription, { isLoading: isActivation }] =
    useReactivateSubscriptionMutation()
  const { data, error, isLoading, refetch } = useGetActiveUsersQuery({
    pageSize: 100,
    pageNumber: currentPage,
    phoneNumber:
      phoneNumber === "" || phoneNumber == null ? phoneNumber : filteredPhone,
  })

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  const handleSearch = () => {
    if (!phoneNumber) {
      setFilterPhone(null)
      return refetch()
    } else {
      setFilterPhone(phoneNumber)
      return refetch()
    }
  }

  const handleReactivate = async (row) => {
    if (row.status === "active") {
      notify("Cannot Reactivate an active Subscription", true)
    } else if (!row.subscription_id) {
      notify("Failed. Customer has no active subscription", true)
    } else {
      try {
        const params = {
          current_sub_id: row.subscription_id,
          action: "full",
          time_used_in_sec: Number(row.used_time_in_sec),
        }
        await reactivateSubscription(params).unwrap()
        refetch()
        notify("Customer Reactivated Successfully", false)
      } catch (error) {
        notify("Failed to Reactivate, Please try again", true)
      }
    }
  }

  const handleReactivateRemaining = async (row) => {
    if (row.status === "active") {
      notify("Cannot Reactivate an active Subscription", true)
    } else if (!row.subscription_id) {
      notify("Failed. Customer has no active subscription", true)
    } else if (Number(row.used_time_in_sec) >= row.plan_duration_secs) {
      notify("Failed. Customer depleted his/her subscription", true)
    } else {
      try {
        const params = {
          current_sub_id: row.subscription_id,
          action: "remaining",
          time_used_in_sec: Number(row.used_time_in_sec),
        }
        await reactivateSubscription(params).unwrap()
        refetch()
        notify("Customer Reactivated Successfully", false)
      } catch (error) {
        notify("Failed to Reactivate, Please try again", true)
      }
    }
  }
  const actions = [
    { title: "Reactivate", handler: handleReactivate },
    { title: "Reactivate Remaining Time", handler: handleReactivateRemaining },
  ]

  // on page change
  useEffect(() => {
    refetch()
  }, [currentPage])

  const formatedData = Array.isArray(data?.customers)
    ? data.customers.map((user, index) => {
        return {
          ...user,
          count: index + 1, // Use index to generate count
          expires_at: formatDate(user.expires_at),
          last_seen: formatDate(user.last_seen),
          activated_at: formatDate(user.activated_at),
        }
      })
    : [] // Return an empty array if data.data is not an array

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isLoading={isLoading}
          error={error}
          onPageChnage={handlePageChange}
          isSelectable={false}
          actions={actions}
          onRefresh={onRefresh}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next:
              currentPage < data?.total_pages
                ? currentPage + 1
                : data?.total_pages,
            previous: currentPage > 1 ? currentPage - 1 : 1,
            pagesTotal: data?.total_pages,
          }}
        />
      </Card.Body>
    </Card>
  )
  return (
    <>
      <Dashboard title={"Hotspot Customer Subscriptions"}>
        <Row className="tw-mb-4">
          <Col>
            <FilterByPhone
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default ActiveUsers
