import React from "react"
import { formatErrors } from "../../helpers"
import { Alert } from "react-bootstrap"

const ErrorMessage = ({ errors }) => {
  if (errors === null) {
    return
  }
  const errorMessages = formatErrors(errors)

  const content = Array.isArray(errorMessages) ? (
    errorMessages.map((errorMessage, index) => {
      return <li key={index}>{errorMessage}</li>
    })
  ) : (
    <li>{errorMessages}</li>
  )

  return (
    <Alert variant="danger">
      <ul className="tw-list-disc tw-ml-3">{content}</ul>
    </Alert>
  )
}

export default ErrorMessage
