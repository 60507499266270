import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"

const CredentialForm = ({ formik, error }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {error && error?.message && (
              <Alert variant="danger">{error?.message}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>PPPoe Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="username"
              />
              {formik.errors.username && formik.touched.username && (
                <Form.Text className="text-danger">
                  {formik.errors.username}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>PPPoe Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="password"
              />
              {formik.errors.password && formik.touched.password && (
                <Form.Text className="text-danger">
                  {formik.errors.password}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default CredentialForm
