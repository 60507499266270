import React, { useState, useEffect } from "react"
import { notify } from "../../../../helpers"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import {
  useAddSubscriptionMutation,
  useEditSubscriptionMutation,
} from "../pppUsersApiSlice"
import CustomDialog from "../../../../components/CustomDialog"
import SubscriptionForm from "./SubscriptionForm"

const subscription_data = {
  plan_id: "",
  zone_id: "",
  landmark: "",
  house_number: "",
  credentials_exists: false,
  username: "",
  password: "",
  ext_account_number: "",
  use_external_account: false,
}

const AddSubscription = ({
  show,
  handleShow,
  handleRefetch,
  action,
  customerId,
  defaultValues,
  plans,
  zones,
}) => {
  const [addSubscription, { isLoading }] = useAddSubscriptionMutation()
  const [editSubscription, { isLoading: isEditing }] =
    useEditSubscriptionMutation()
  const [errors, setErrors] = useState(null)
  const [initialValues, setInitialValues] = useState(subscription_data)
  const [operation, setOperation] = useState(action)
  const [title, setTitle] = useState("Add Subscription")

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })


  useEffect(() => {
    if (operation === "edit_subscription") {
      formik.setValues(defaultValues)
      setInitialValues(defaultValues)
      setTitle("Edit Subscription")
    } else {
      formik.setValues(subscription_data)
      setInitialValues(subscription_data)
      setTitle("Add Subscription")
    }
  }, [operation, defaultValues])


  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const subscription = {
      ...values,
      customer_id: customerId
    }
    // do logic to save data
    if (operation === "add_subscription") {
      try {
        await addSubscription(subscription).unwrap()
        resetForm()
        handleShow(false)
        setErrors(null)
        handleRefetch(true)
        notify("Subscription added Successfuly", false)
      } catch (error) {
        setErrors(error?.data.errors || error?.data)
        notify("Failed. please try again", true)
      }
    } else {
      try {
        await editSubscription({
          ...subscription,
          id: defaultValues.id
        }).unwrap()
        resetForm()
        handleShow(false)
        setErrors(null)
        handleRefetch(true)
        notify("Subscription updated Successfuly", false)
      } catch (error) {
        setErrors(error?.data?.errors || error?.data)
        notify("Failed. please try again", true)
      }
    }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    setErrors(null)
    formik.resetForm()
  }

  return (
    <>
      <Form method="post" onSubmit={formik.handleSubmit} autoComplete="off">
        <CustomDialog
          show={show}
          title={title}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isLoading || isEditing}
          size={"lg"}
        >
          <SubscriptionForm
            formik={formik}
            error={errors}
            clearErrors={setErrors}
            plans={plans}
            zones={zones}
            action={action}
          />
        </CustomDialog>
      </Form>
    </>
  )
}

// validation
const validationSchema = Yup.object().shape({
  plan_id: Yup.number().required("please select plan"),
  zone_id: Yup.number().required("please select Zone"),
  landmark: Yup.string().required("Landmark is required"),
  house_number: Yup.string().required("House Number/Apartment is required"),
  credentials_exists: Yup.boolean().optional().default(false),
  use_external_account: Yup.boolean().optional().default(false),
  username: Yup.string().when("credentials_exists", {
    is: true,
    then: (schema) => schema.required("Username is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  password: Yup.string().when("credentials_exists", {
    is: true,
    then: (schema) => schema.required("password is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  ext_account_number: Yup.string().when("use_external_account", {
    is: true,
    then: (schema) => schema.required("Account is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
})

export default AddSubscription
