import { apiSlice } from "../../../app/api/apiSlice"

export const hotspotAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHotspots: builder.query({
      query: () => ({
        url: "/api/isp/hotspots",
      }),
    }),
    getRouters: builder.query({
      query: () => ({
        url: "/api/isp/routers",
      }),
    }),
    createHotspot: builder.mutation({
      query: (hotspot) => ({
        url: "/api/isp/hotspots",
        method: "POST",
        body: { 
          params: hotspot
         },
      }),
    }),

    deleteHotspot: builder.mutation({
      query: (hotspot_id) => ({
        url: `/api/isp/hotspots/${hotspot_id}`,
        method: "DELETE"
      }),
    }),

    updateHotspot: builder.mutation({
      query: (hotspot) => ({
        url: `/api/isp/hotspots/${hotspot.id}`,
        method: "PUT",
        body: {
          params: hotspot
        },
      }),
    }),
  }),
})

export const {
  useGetHotspotsQuery,
  useCreateHotspotMutation,
  useUpdateHotspotMutation,
  useGetRoutersQuery,
  useDeleteHotspotMutation
} = hotspotAPiSlice
