import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import {
  useGeneralDashboardStatsQuery,
  useIncomePerMonthQuery,
  useIncomeInPast7DaysQuery,
  useIncomePerPlanQuery,
} from "./dashboardApiSlice"
import { Col, Row } from "react-bootstrap"
import IncomePerMonth from "./incomePerMonth"
import IncomeInLast7Days from "./IncomeInLast7Days"
import SummaryCard from "./../SummaryCard"
import { formatMoney } from "../../../helpers"
import FilterByYear from "../../../components/FilterByYear"
import { useGetPackagesQuery } from "../../Hotspot/Plans/packageApiSlice"
import { useGetHotspotsQuery } from "../../Hotspot/Plans/packageApiSlice"
import IncomePerPlan from "./incomePerPlan"
import IncomePerHotspot from "./incomePerHotspot"

const date = new Date()
const startYear = 2023
const currentYear = date.getFullYear()
const filteryears = []

for (let x = startYear; x <= currentYear; x++) {
  filteryears.push(x)
}

const FinanceDashboard = () => {
  const [year, setYear] = useState(currentYear)
  const {
    data = {},
    error,
    isLoading,
    refetch,
  } = useGeneralDashboardStatsQuery(year)
  const { data: monthlyIncome = {} } = useIncomePerMonthQuery(year)
  const { data: past7DaysIncome = {} } = useIncomeInPast7DaysQuery()
  const { data: incomePerPlan = {} } = useIncomePerPlanQuery(year)
  const { data: plans = {} } = useGetPackagesQuery()
  const { data: hotspots = {} } = useGetHotspotsQuery()

  const options = filteryears.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    )
  })

  const handleYearOnChange = (e) => {
    const value = Number(e.target.value)
    if (value === 1) {
      setYear(currentYear)
    } else {
      setYear(value)
    }
  }

  //
  useEffect(() => {
    refetch()
  }, [year])

  const generalData = data?.data
  const incomeToday = generalData?.income_today || 0
  const totalCustomers = generalData?.total_customers || 0
  const totalActiveUsers = generalData?.current_active || 0
  const totalIncomeThisMonth = generalData?.income_this_month || 0
  const totalBill = Number(totalIncomeThisMonth) * 0.1 || 0

  return (
    <>
      <Dashboard title={"Hostpot Dashboard"}>
        <FilterByYear
          options={options}
          value={year}
          handleYearOnChange={handleYearOnChange}
        />
        <Row className="mt-3">
          <Col xs="12" md={7}>
            <Row>
              <Col xs="6" className="my-2" sm>
                <SummaryCard
                  title={"Total Customers"}
                  color={"text-success"}
                  value={totalCustomers}
                />
              </Col>
              <Col xs="6" className="my-2" sm>
                <SummaryCard
                  title={"Total Active Users"}
                  color={""}
                  value={totalActiveUsers}
                />
              </Col>
              <Col xs="6" className="my-2" sm>
                <SummaryCard
                  title={"Income This Month"}
                  color={"text-secondary"}
                  value={`${formatMoney(Number(totalIncomeThisMonth))}`}
                />
              </Col>
              <Col xs="6" className="my-2" sm>
                <SummaryCard
                  title={"Estimated Bill This Month"}
                  color={"text-warning"}
                  value={`${formatMoney(Number(totalBill))}`}
                />
              </Col>
              <Col xs="6" className="my-2" sm>
                <SummaryCard
                  title={"Income Today"}
                  color={"text-success"}
                  value={`${formatMoney(Number(incomeToday))}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm className="card-one p-3">
                <IncomePerMonth data={monthlyIncome.data || []} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12" sm className="card-one p-3">
                <IncomePerPlan
                  hotspots={hotspots?.data || []}
                  data={incomePerPlan?.data || []}
                  plans={plans?.data || []}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" md={5}>
            <Row>
              <Col xs="12" className="card-one p-3">
                <IncomeInLast7Days data={past7DaysIncome.data || []} />
              </Col>
              <Col xs="12" className="card-one p-3">
                <IncomePerHotspot
                  hotspots={hotspots?.data || []}
                  data={incomePerPlan?.data || []}
                  plans={plans?.data || []}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default FinanceDashboard
