import { apiSlice } from "../../../app/api/apiSlice"

export const pppUsersAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listPPPUsers: builder.query({
      query: (params) => {
        let url = "/api/isp/ppoe/customers"
        if (params.pageSize) {
          url = url + `?page_size=${params.pageSize}`
        }
        if (params.pageNumber) {
          url = url + `&page_number=${params.pageNumber}`
        }
        if (params.phoneNumber) {
          url = url + `&phone_number=${params.phoneNumber}`
        }
        return {
          url,
        }
      },
    }),
    createPPPUser: builder.mutation({
      query: (customer) => ({
        url: "/api/isp/ppoe/customers",
        method: "POST",
        body: {
          params: customer,
        },
      }),
    }),

    editPPPUser: builder.mutation({
      query: (customer) => ({
        url: `/api/isp/ppoe/customers/${customer.uuid}`,
        method: "PUT",
        body: {
          params: customer,
        },
      }),
    }),

    deletePPPUser: builder.mutation({
      query: (customer_id) => ({
        url: `/api/isp/ppoe/customers/${customer_id}`,
        method: "DELETE",
      }),
    }),

    addSubscription: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions`,
        method: "POST",
        body: {
          params: subscription,
        },
      }),
    }),

    editSubscription: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions/${subscription.id}`,
        method: "PUT",
        body: {
          params: subscription,
        },
      }),
    }),
    changeCredentials: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions/${subscription.id}/change_credentials`,
        method: "PUT",
        body: {
          params: subscription,
        },
      }),
    }),
    deletePPPSubscription: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions/${subscription.id}`,
        method: "DELETE",
      }),
    }),
    activatePPPUser: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions/${subscription.id}/activate`,
        method: "POST",
        body: {
          params: subscription,
        },
      }),
    }),
    deactivatePPPUser: builder.mutation({
      query: (subscription) => ({
        url: `/api/isp/ppoe/customers/${subscription.customer_id}/subscriptions/${subscription.id}/deactivate`,
        method: "POST",
        body: {},
      }),
    }),
    getPPPUser: builder.query({
      query: (customerId) => `/api/isp/ppoe/customers/${customerId}`,
    }),
  }),
})

export const {
  useListPPPUsersQuery,
  useCreatePPPUserMutation,
  useEditPPPUserMutation,
  useDeletePPPUserMutation,
  useGetPPPUserQuery,
  // subscrption
  useAddSubscriptionMutation,
  useEditSubscriptionMutation,
  useChangeCredentialsMutation,
  useDeletePPPSubscriptionMutation,
  useActivatePPPUserMutation,
  useDeactivatePPPUserMutation,
} = pppUsersAPiSlice
