import { apiSlice } from "../../../app/api/apiSlice";

export const allTransferReportAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllTranferReport: builder.query({
            query: (params)=> {
                let url = '/api/isp/transactions?'
                if(params.pageSize) {
                    url = url + `page_size=${params.pageSize}`
                }
                if(params.pageNumber) {
                    url = url + `&page_number=${params.pageNumber}`
                }
                return {
                    url
                }
            }
        }),

    })
});

export const { useGetAllTranferReportQuery } =  allTransferReportAPiSlice;