import { apiSlice } from "../../../app/api/apiSlice";

export const resellerInfoAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getResellerInfo: builder.query({
            query: ()=> ({
                url: '/api/isp',
            })
        }),

    })
});

export const { useGetResellerInfoQuery } =  resellerInfoAPiSlice;