import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import CustomDialog from "../../../../components/CustomDialog"
import CredentialForm from "./CredentialForm"
import { useChangeCredentialsMutation } from "../pppUsersApiSlice"
import { notify } from "../../../../helpers"

const ChangeCredentials = ({ show, handleShow, subscription, customerId, handleRefetch }) => {
  const [changeCredentials, { isLoading }] =
  useChangeCredentialsMutation()
  const [error, setError] = useState(null)

  const [initialValues] = useState({
    username: '',
    password: ''
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    }
  })

  useEffect(()=> {
    formik.setValues({
      username: subscription.username,
      password: subscription.password
    })
  }, [subscription])

  const saveAndCLose = async (values, reset) => {
    const credentials = {
       ...values,
       id: subscription.id,
       customer_id: customerId
      }
      try {
        await changeCredentials(credentials).unwrap()
        reset()
        handleShow(false)
        setError(null)
        handleRefetch(true)
        notify('Credentials Updated Successfully', false)
      } catch (error) {
        setError(error.data)
        notify('Failed. please try again', true)
      }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    formik.resetForm()
  }

  return (
    <Form method="post" onSubmit={formik.handleSubmit}>
      <CustomDialog
        show={show}
        title={"Update Credentials"}
        onCLose={handleCloseDialog}
        onSave={formik.handleSubmit}
        isLoading={isLoading}
        size={'lg'}
      >
        <CredentialForm
          formik={formik}
          error={error}
        />
      </CustomDialog>
    </Form>
  )
}

// validation
const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
})

export default ChangeCredentials
