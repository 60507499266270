import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Form, Card, Col, Row, Button } from "react-bootstrap"
import {
  useCreatePppPackageMutation,
  useEditPppPackageMutation,
  useGetPppPackagesQuery,
  useDeletePppPackageMutation
} from "./packageApiSlice"
import MainGrid from "../../../components/MainGrid"
import Dashboard from "../../../components/Dashboard"
import { formatime, notify } from "../../../helpers"
import CustomDialog from "../../../components/CustomDialog"
import PackageForm from "./PackageForm"

const columns = [
  { name: "itemId", description: "#" },
  { name: "uuid", description: "Plan Id" },
  { name: "name", description: "Package Name"},
  { name: "upload_speed", description: "Upload Speed" },
  { name: "download_speed", description: "Download Speed" },
  { name: "bundle_size", description: "Bunde Size" },
  { name: "price", description: "Price" },
  { name: "duration", description: "Duration" },
  { name: "pool_name", description: "Pool Name" }
]

export default function PppPackages() {
const {data, error: fetchErrors, isLoading, refetch} = useGetPppPackagesQuery()
  const [createPackage, { isLoading: isCreating }] = useCreatePppPackageMutation()
  const [editPackage, { isLoading: isEditing }] = useEditPppPackageMutation()
  const [deletePackage, { isLoading: isDeleting }] = useDeletePppPackageMutation()

  const [selected, setSelected] = useState(null)
  const [createError, setCreateError] = useState(null)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Create PPP Plan")
  const [initialValues, setInitialValues] = useState({
    name: "",
    bundle_size:  "",
    price: "",
    duration: "",
    time_unit: "",
    upload_speed: "",
    download_speed: ""
  })



  // modal logic
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Create PPP Plan")
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const plan = {
      bundle_size: Number(values.bundle_size),
      price: Number(values.price),
      duration: Number(values.duration),
      time_unit: values.time_unit,
      name: values.name,
      upload_speed: Number(values.upload_speed),
      download_speed: Number(values.download_speed)
    }
    // do logic to save data
    if (action === "add") {
      try {
        await createPackage(plan).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        notify('PPP package added Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    } else {
      try {
        const editedPackage = await editPackage({id:selected.id, ...plan}).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        setInitialValues(editedPackage)
        refetch()
        notify('PPP package edited Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    }
  }

  const handleDelete = async (row) => {
    try {
      await deletePackage(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Plan Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    const plan = (data?.data || []).find(p => p.id === row.id)
    setShow(true)
    setAction("edit")
    setTitle("Edit PPPoe Plan")

    const values  = {
      name: plan.name,
      bundle_size: plan.bundle_size,
      price: plan.price,
      duration: plan.duration,
      time_unit: plan.time_unit,
      upload_speed: plan.upload_speed,
      download_speed: plan.download_speed
    }
    setInitialValues(values)
    setSelected(plan)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]


  
  useEffect(()=> {
  }, [selected])

  // columns
let itemId = 0
  const formatedData = (data?.data || []).map((plan) => {
    itemId ++
    return {
      ...plan,
      upload_speed: `${plan.upload_speed} Mbps`,
      download_speed: `${plan.download_speed} Mbps`,
      price: `${plan.currency} ${Number(plan.price).toFixed(2)}`,
      duration: formatime(plan.duration, plan.time_unit),
      bundle_size: `${plan.bundle_size} ${plan.bundle_unit}`,
      fup_enabled: plan.fup_enabled ? 'true': 'false',
      itemId: itemId

    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading || isEditing || isDeleting}
          error={fetchErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )
  return (
    <React.Fragment>
      <Dashboard title="PPPoe Plans">
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Plan{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <Form method="post" onSubmit={formik.handleSubmit}>
        <CustomDialog
          show={show}
          title={title}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isCreating}
          size={'lg'}
          
        >
          <PackageForm formik={formik} error={createError } defaultData={selected} />
        </CustomDialog>
      </Form>
    </React.Fragment>
  )
}



// validation
const validationSchema = Yup.object({
  name: Yup.string().required("Plan name must be specified"),
  price: Yup.number().required("Price is required"),
  duration: Yup.number().required("duration is required"),
  time_unit: Yup.string().required("time unit must be specified"),
  bundle_size: Yup.number().required("bundle is required"),
  upload_speed: Yup.number().required("Upload speed is required"),
  download_speed: Yup.number().required("donwload speed is required"),
})
