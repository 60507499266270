import { apiSlice } from "../../../app/api/apiSlice"

export const dashboardAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pppGeneralDashboardStats: builder.query({
      query: (year) => {
        return { url: `/api/isp/ppoe/dashboard/general-stats?year=${year}` }
      },
    }),
    pppIncomePerMonth: builder.query({
      query: (year) => {
        return {
          url: `/api/isp/ppoe/dashboard/income-per-month?service=ppoe&year=${year}`,
        }
      },
    }),
    pppIncomePerPlan: builder.query({
      query: (year) => {
        return {
          url: `/api/isp/ppoe/dashboard/income-per-plan?service=ppoe&year=${year}`,
        }
      },
    }),
    pppSubsExpiringToday: builder.query({
      query: () => {
        return { url: `/api/isp/ppoe/dashboard/subscriptions-expiring-today` }
      },
    }),
  }),
})

export const {
  usePppGeneralDashboardStatsQuery,
  usePppIncomeInPast7DaysQuery,
  usePppIncomePerMonthQuery,
  usePppIncomePerPlanQuery,
  usePppSubsExpiringTodayQuery,
} = dashboardAPiSlice
