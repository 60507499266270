import React from "react"
import { Table } from "react-bootstrap"

const ISPDetails = ({ data }) => {
  return (
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{data?.name}</td>
            </tr>
            <tr>
              <td>email Address</td>
              <td>{data?.email}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{data?.phone}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{data?.country}</td>
            </tr>
            <tr>
              <td>Region</td>
              <td>{data?.region}</td>
            </tr>
            <tr>
              <td>Website</td>
              <td>{data?.website}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{data?.enabled ? "enabled" : "disabled"}</td>
            </tr>
            <tr>
              <td>Company Id</td>
              <td>{data?.company_id}</td>
            </tr>
            <tr>
              <td>Physical Address</td>
              <td>{data?.address}</td>
            </tr>
          </tbody>
        </Table>
  )
}

export default ISPDetails
