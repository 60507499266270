import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetHotspotTranferReportQuery } from "./transferReportApiSlice"
import { Col, Row, Card } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { formatDate, formatTimeInZ } from "../../../helpers"
import { useGetPackagesQuery } from "../Plans/packageApiSlice"
import { useGetHotspotsQuery } from "../../Configuration/Hotspots/hotspotApiSlice"
import { useGetRoutersQuery } from "../../Configuration/Hotspots/hotspotApiSlice"


const getPlan = (uuid, plans) => {
  return plans.find(p => p.uuid === uuid) || {}
}

const getHostpot = (id, hotspots) => {
  return hotspots.find(h => h.id === id) || {}
}

const getRouter = (uuid, routers) => {
  return routers.find(r => r.router_id === uuid) || {}
}



const columns = [
  { name: "id", description: "#" },
  { name: "status", description: "Payment Status" },
  { name: "amount", description: "Amount" },
  { name: "source", description: "Phone Number" },
  { name: "destination", description: "Destination" },
  { name: "transferdatetime", description: "Transfer Date Time" },
  { name: "plan_name", description: "Plan" },
  { name: "hotspot_name", description: "Hotspot" },
  { name: "router_name", description: "Router" },
  { name: "description", description: "Description" },
];

const TransferReport = () => {
  const { data: plans = [], isLoading: isLoadingPlan } = useGetPackagesQuery(null)
  const { data: hotspots = [], isLoading: isHotspotLoading } = useGetHotspotsQuery()
  const { data: routers = [], isLoading: isroutersLoading } = useGetRoutersQuery()
  
  const [currentPage, setCurrentPage] = useState(1)
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useGetHotspotTranferReportQuery({ pageSize: 100, pageNumber: currentPage })


  //
  // on page change
  useEffect(()=> {
    refetch()
  }, [currentPage])


  const handlePageChange = (page) => {
    setCurrentPage(page)
  }



  const formatedData = (data?.transactions || []).map(tx => {
    const plan= getPlan(tx.plan_id, plans?.data || [])
    const hotspot = getHostpot(plan?.hotspot_id, hotspots?.data || [])
    const router = getRouter(hotspot.router_id, routers?.data || [] )
    return {
        ...tx, 
        amount: `${tx.currency} ${Number(tx.amount).toFixed(2)}`,
        transferdatetime: formatDate(tx.transferdatetime),
        plan_name: plan?.name,
        hotspot_name: hotspot?.name,
        router_name: router?.description
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading || isLoadingPlan || isHotspotLoading || isroutersLoading}
          error={error}
          onPageChnage = {handlePageChange}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next: currentPage < data?.total_pages ? currentPage + 1 : data?.total_pages, 
            previous: currentPage > 1 ? currentPage - 1 : 1, 
            pagesTotal: data?.total_pages
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Hotspot Transactions"}>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default TransferReport
