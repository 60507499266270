import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../pages/Auth/authSlice';
const env = process.env.REACT_APP_ENV
const authUrl = env === 'uat' ? process.env.REACT_APP_UAT_AUTH_BASE_URL : process.env.REACT_APP_AUTH_BASE_URL
const appUrl =  env === 'uat' ? process.env.REACT_APP_UAT_PORTAL_URL : process.env.REACT_APP_PORTAL_URL
const baseQuery = fetchBaseQuery({
    baseUrl:  appUrl,
    timeout: 20000,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.access_token
        if(token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json')
        }
        return headers;
    }
});


const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
   const  status = result?.error?.status

    // token has  expired
    if(status == 401 || status == 403) {
        const token = localStorage.getItem('ch-token') || false;
        if(token) {
            const refreshResult = await baseQuery({
                url: `${authUrl}/v1/api/auth/token/renew`,
                method: 'POST',
                body: {
                    refresh_token: token
                }
            }, api, extraOptions);
            if(refreshResult?.data?.data) {
                const user = api.getState().auth.user;
                api.dispatch(setCredentials({ ...refreshResult.data.data, user }));
                // retry the original query with new access token 
                result = await baseQuery(args, api, extraOptions);
            }else {
                localStorage.removeItem('ch-token');
                api.dispatch(logOut());
            }

        }else {
            api.dispatch(logOut());
        }

    }
    return result;
}



export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})