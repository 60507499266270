import React, {useState, useEffect} from "react"
import CustomDialog from "../../../components/CustomDialog"
import { Form, Col, Card, Row, Alert } from "react-bootstrap"
const zone_types = ["residential", "commercial", "industrial", "mixed"]
const ZoneForm = ({
  show,
  title,
  handleCloseDialog,
  formik,
  isLoading,
  errors,
  countries
}) => {
  const [counties, setCounties] = useState([])
  const [country, setCountry] = useState(null)

  const searchCounties  = (country_id) => {
    const country = (countries || []).find(country => country.id === Number(country_id))
    setCounties(country?.counties || [])
  }

  useEffect(()=> {
    searchCounties(country)
  }, [country])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCountry(value)
    formik.setFieldValue(name, value);
  };



  const zoneCountryOptions = (countries || []).map((country) => {
    return (
      <option key={country.id} value={country.id}>
        {`${country.name}`}
      </option>
    )
  })

  const zoneCountyOptions = (counties || []).map((county) => {
    return (
      <option key={county.id} value={county.id}>
        {`${county.name}`}
      </option>
    )
  })

  const zoneTypesOptions = zone_types.map((type, index) => {
    return (
      <option key={index} value={type}>
        {type}
      </option>
    )
  })

  return (
    <CustomDialog
      show={show}
      title={title}
      onCLose={handleCloseDialog}
      onSave={formik.handleSubmit}
      isLoading={isLoading}
      size={"lg"}
    >
      <Form method="post" onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                {errors && errors?.message && (
                  <Alert variant="danger">{errors?.message}</Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <Form.Text className="text-danger">
                      {formik.errors.name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                  />
                  {formik.errors.description && formik.touched.description && (
                    <Form.Text className="text-danger">
                      {formik.errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    value={formik.values.country_id}
                    onChange={handleInputChange}
                    onBlur={formik.handleBlur}
                    name="country_id"
                  >
                    <option>Select Country</option>
                    {zoneCountryOptions}
                  </Form.Select>
                  {formik.errors.country_id && formik.touched.country_id && (
                    <Form.Text className="text-danger">
                      {formik.errors.country_id}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>County</Form.Label>
                  <Form.Select
                    value={formik.values.county_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="county_id"
                  >
                    <option>Select County</option>
                    {zoneCountyOptions}
                  </Form.Select>
                  {formik.errors.county_id && formik.touched.county_id && (
                    <Form.Text className="text-danger">
                      {formik.errors.county_id}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Capacity</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Capacity"
                    value={formik.values.capacity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="capacity"
                  />
                  {formik.errors.capacity &&
                    formik.touched.capacity && (
                      <Form.Text className="text-danger">
                        {formik.errors.capacity}
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Coverage Area</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coverage Area"
                    value={formik.values.coverage_area}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="coverage_area"
                  />
                  {formik.errors.coverage_area && formik.touched.coverage_area && (
                    <Form.Text className="text-danger">
                      {formik.errors.coverage_area}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col>
                <Form.Group>
                  <Form.Label>Zone Type</Form.Label>
                  <Form.Select
                    value={formik.values.zone_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="zone_type"
                  >
                    <option>Select Zone Type</option>
                    {zoneTypesOptions}
                  </Form.Select>
                  {formik.errors.zone_type && formik.touched.zone_type && (
                    <Form.Text className="text-danger">
                      {formik.errors.zone_type}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Installation Date</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Installation Date - YYYY-MM-DD"
                    value={formik.values.installation_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="installation_date"
                  />
                  {formik.errors.installation_date && formik.touched.installation_date && (
                    <Form.Text className="text-danger">
                      {formik.errors.installation_date}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </CustomDialog>
  )
}

export default ZoneForm
