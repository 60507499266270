import { apiSlice } from "../../../app/api/apiSlice"

export const packageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: (hotspotId) => {
        if (hotspotId) {
          return { url: `/api/isp/hotspots/plans?hotspot_id=${hotspotId}` }
        }
        return { url: `/api/isp/hotspots/plans` }
      },
    }),
    getHotspots: builder.query({
      query: () => {
        return { url: `/api/isp/hotspots` }
      },
    }),
    createPackage: builder.mutation({
      query: (plan) => ({
        url: "/api/isp/hotspots/plans",
        method: "POST",
        body: {
          params: plan,
        },
      }),
    }),
    deletePackage: builder.mutation({
        query: (plan_id) => ({
          url: `/api/isp/hotspots/plans/${plan_id}`,
          method: "DELETE"
        }),
      }),
    editPackage: builder.mutation({
      query: (plan) => {
        return {
          url: `/api/isp/hotspots/plans/${plan.id}`,
          method: "PUT",
          body: {
            params: plan,
          },
        }
      },
    }),
  }),
})

export const {
  useCreatePackageMutation,
  useGetPackagesQuery,
  useEditPackageMutation,
  useGetHotspotsQuery,
  useDeletePackageMutation
} = packageApiSlice
