import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import {
  useGetHotspotsQuery,
  useCreateHotspotMutation,
  useUpdateHotspotMutation,
  useGetRoutersQuery,
  useDeleteHotspotMutation
} from "./hotspotApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { notify } from "../../../helpers"
import HotspotForm from "./HotspotForm"

const columns = [
  { name: "count", description: "#" },
  { name: "uuid", description: "ID" },
  { name: "status", description: "Status" },
  { name: "name", description: "Hotspot Name" },
  { name: "bridge_name", description: "Bridge Name" },
  { name: "router_id", description: "Router Id" },
  { name: "description", description: "Description" },
  { name: "landmark", description: "Landmark" },
]

const Hotspots = () => {
  // state
  const {
    data: hotspots,
    error: hotspotErrors,
    isLoading: isLoadingHotspots,
    refetch,
  } = useGetHotspotsQuery()
  const {
    data: routers,
    error: routerErrors,
    isLoading: isFetchingRouters,
  } = useGetRoutersQuery()
  const [createHotspot, { isLoading: isCreating }] = useCreateHotspotMutation()
  const [updateHotspot, { isLoading: isEditing }] = useUpdateHotspotMutation()
  const [deleteHotspot, { isLoading: isDeleting }] = useDeleteHotspotMutation()
  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Hotspot")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    name: "",
    bridge_name: "",
    description: "",
    router_id: "",
    landmark: "",
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }


  // handlers
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Add Hotspot")
    setFormErrors(null)
  }


  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addHotspot = async (values, resetForm) => {
    try {
      await createHotspot(values).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("Hotspot added Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error.data)
      notify("Failed. Please try again", true)
    }
  }

  const editHotspot = async (values, resetForm) => {
    try {
      await updateHotspot({ ...values, id: selected.id }).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("Hotspot updated Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error.data)
      notify("Failed. Please try again", true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if (action === "add") {
      await addHotspot(values, resetForm)
    } else {
      await editHotspot(values, resetForm)
    }
  }


  const handleDelete = async (row) => {
    try {
      await deleteHotspot(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Hotspot Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Hotspot")
    setFormErrors(null)
    const values  = {
      name: row.name,
      bridge_name: row.bridge_name,
      description: row.description,
      router_id: row.router_id,
      landmark: row.landmark
    }
    setInitialValues(values)
    setSelected(row)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]

  // on state changes
  useEffect(() => {
    if (isCreating || isEditing) {
      setInprogress(true)
    } else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])

  // format to render
  let count = 0
  const formatedData = (hotspots?.data || []).map((hotspot) => {
    count++
    return {
      ...hotspot,
      count
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoadingHotspots || isFetchingRouters}
          error={hotspotErrors || routerErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Hotspots"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Hotspot{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <HotspotForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
        routers={routers}
      />
    </>
  )
}

export default Hotspots

// validation
const validationSchema = Yup.object({
  name: Yup.string().required("hotspot name is required"),
  bridge_name: Yup.string().required("interfaceName/Bridge Name is required"),
  description: Yup.string().required("description is required"),
  router_id: Yup.string().required("routerId must be specified"),
  landmark: Yup.string().required("landmark name is required"),
})
