import React from "react"
import { Bar } from "react-chartjs-2"
import "chart.js/auto"
import { Card } from "react-bootstrap"

const IncomePerMonth = ({ data }) => {
  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  }

  const dataMonths = (data || []).reduce(
    (accum, current) => ({ ...accum, [current.month]: current.amount }),
    {}
  )

  const cxData = Object.keys(months).map((m) => {
    if (Object.keys(dataMonths).includes(m)) {
      return Number(dataMonths[m])
    } else {
      return 0
    }
  })

  const dataBar = {
    labels: Object.values(months),
    datasets: [
      {
        data: cxData,
        backgroundColor: "#506fd9",
        barPercentage: 0.5,
      },
    ],
  }

  const optionBar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <Card className="mt-2 ">
      <Card.Header>
        <Card.Title as="h6">Income Per Month in KES</Card.Title>
      </Card.Header>
      <Card.Body>
        <Bar data={dataBar} options={optionBar} className="ht-300" />
      </Card.Body>
    </Card>
  )
}

export default IncomePerMonth
