import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import { 
  useGetRoutersQuery, 
  useCreateRouterMutation, 
  useUpdateRouterMutation,
  useDeleteRouterMutation
} from "./routerApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import RouterForm from "./RouterForm"
import { notify } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "router_id", description: "Router ID" },
  { name: "status", description: "Router Status" },
  { name: "nasname", description: "Router IP Address" },
  { name: "type", description: "Router Type" },
  { name: "server", description: "Radius Server" },
  { name: "description", description: "Description" },
  { name: "company_id", description: "Isp Id" }
]

const Routers = () => {
  // state
  const {
    data,
    error: fetchRoutersErrors,
    isLoading: isFetching,
    refetch
  } = useGetRoutersQuery()

  const [createRouter, { isLoading: isCreating }] = useCreateRouterMutation()
  const [updateRouter, { isLoading: isEditing }] = useUpdateRouterMutation()
  const [deleteRouter, { isLoading: isDeleting }] = useDeleteRouterMutation()

  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Router")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    nasname: "",
    description: "",
    server: "",
    type: "",
    shortname: "",
    secret: ""
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  // handlers
  const handleShowModalToAdd = () => {
    
    setShow(true)
    setAction("add")
    setTitle("Add Router")
    setFormErrors(null)
  }


  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addRouter = async (values, resetForm)=> {
    try {
      await createRouter(values).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router added Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const editRouter = async (values, resetForm)=> {
    try {
      await updateRouter({...values, id: selected.id}).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router updated Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if(action === 'add') {
      await addRouter(values, resetForm);
    }else {
      await editRouter(values, resetForm);
    }
  }


  const handleDelete = async (row) => {
    try {
      await deleteRouter(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Router Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Router")
    setFormErrors(null)
    const values  = {
      nasname:  row?.nasname,
      description: row?.description,
      server: row?.server,
      type: row?.type,
      shortname: row?.shortname,
       secret: row?.secret
    }
    setInitialValues(values)
    setSelected(row)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]

  // on state changes
  useEffect(() => {
    if(isCreating || isEditing) {
      setInprogress(true)
    }else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])

  // table format
  let count = 0
  const routerData = (data?.data || []).map((router) => {
    count++
    return {
      ...router,
      count,
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={routerData}
          isSelectable={false}
          isLoading={isFetching}
          error={fetchRoutersErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Routers"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Router{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <RouterForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
      />
    </>
  )
}

export default Routers

//validate ip ip
function ipv4(message = "Invalid IP address") {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true,
  }).test("ip", message, (value) => {
    return value === undefined || value.trim() === ""
      ? true
      : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined
  })
}


// validation
const validationSchema = Yup.object({
  ipAddress: Yup.string().required("please enter valid ipv4 address"),
  description: Yup.string().required("description is required"),
  server: Yup.string().required("radius server is required"),
  type: Yup.string().required("router type must be specified"),
  shortname: Yup.string().required("router type must be specified"),
  secret: Yup.string().required("router type must be specified")
})
