import React from "react"
import { Pagination } from "react-bootstrap"

const DefaultPagination = ({ pagination, onPageChnage }) => {
  const { currentPage, next, previous, pagesTotal } =
    pagination

  const handlePageClick = (page) => {
    if (page !== currentPage) {
        onPageChnage(page)
    }
  }

  const renderPaginationItems = () => {
    const items = []

    // Add the first 5 pages
    for (let page = 1; page <= Math.min(5, pagesTotal); page++) {
        items.push(
            <Pagination.Item
                active={page === currentPage}
                key={page}
                onClick={() => handlePageClick(page)}
            >
                {page}
            </Pagination.Item>
        )
    }

    // Add ellipsis if needed
    if (currentPage > 6) {
        items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }

    // Ensure the current page is shown if it's not in the first 5 or last 5
    if (currentPage > 5 && currentPage <= pagesTotal - 5) {
        items.push(
            <Pagination.Item
                active={currentPage === currentPage}
                key={currentPage}
                onClick={() => handlePageClick(currentPage)}
            >
                {currentPage}
            </Pagination.Item>
        );
    }

    // Add the last 5 pages
    if (pagesTotal > 5) {

        for (let page = Math.max(pagesTotal - 4, currentPage + 1); page <= pagesTotal; page++) {
            if (page > 5 && currentPage <= pagesTotal - 5) {
                items.push(<Pagination.Ellipsis key="ellipsis-end" />);
                break;
            }
            items.push(
                <Pagination.Item
                    active={page === currentPage}
                    key={page}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }
    }


    return items;
}

  return (
    <div className="pagination">
      <h6>showing {currentPage} of {pagesTotal}</h6>
      <Pagination>
        <Pagination.First onClick={()=> handlePageClick(1)} disabled={currentPage ===1 } />
        <Pagination.Prev onClick={()=> handlePageClick(previous)} disabled={currentPage === 1 || currentPage <0 } />
        {renderPaginationItems()}
        <Pagination.Next onClick={()=> handlePageClick(next)} disabled={currentPage === pagesTotal} />
        <Pagination.Last onClick={()=> handlePageClick(pagesTotal)} disabled={currentPage ===pagesTotal } />
      </Pagination>
    </div>
  )
}

export default DefaultPagination
