import React, { useState, useEffect } from "react"
import { Doughnut } from "react-chartjs-2"
import "chart.js/auto"
import { Card } from "react-bootstrap"
import { generateRandomColors } from "../../../helpers/randomColors"

const IncomePerPlan = ({ data, plans }) => {
  const [planData, setPlanData] = useState([])

  const getPlan = (planId) => {
    return plans.find((p) => p.uuid === planId)
  }

  useEffect(() => {
    const formatedPlans = (data || []).reduce((accum, current) => {
      const plan = getPlan(current.plan_id)
      if (plan) {
        return {
          ...accum,
          [plan.name]: (accum[plan.name] || 0) + Number(current.amount),
        }
      }
      return accum // Always return the accumulator
    }, {})
    setPlanData(formatedPlans)
  }, [data, plans])

  const colors = generateRandomColors(Object.keys(planData).length)

  const dataPie = {
    labels: Object.keys(planData),
    datasets: [
      {
        data: Object.values(planData),
        backgroundColor: colors,
      },
    ],
  }

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  }
  return (
    <Card className="ml-5">
      <Card.Header>
        <Card.Title as="h6">Total Income Per Plan in KES</Card.Title>
      </Card.Header>
      <Card.Body>
        <Doughnut data={dataPie} options={optionPie} className="ht-350" />
      </Card.Body>
    </Card>
  )
}

export default IncomePerPlan
