import React from "react"
import { Table } from "react-bootstrap"

const PaymentPlatforms = ({ platforms }) => {
  const content = Array.isArray(platforms)
    ? platforms.map((platform, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{platform.enabled ? "enabled" : "disabled"}</td>
            <td>{platform.name}</td>
            <td>{platform.type}</td>
            <td>{platform.config.key}</td>
            <td>{platform.config.secret}</td>
            <td>{platform.config.collection_account}</td>
            <td>{platform.config.shortcode}</td>
            <td>{platform.config.transfer_type}</td>
            <td>{platform?.service}</td>
            <td>{platform?.currency}</td>
          </tr>
        )
      })
    : []

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Status</th>
            <th>Platform Name</th>
            <th>Platform Type</th>
            <th>Key</th>
            <th>Secret</th>
            <th>Collection Account</th>
            <th>Shortcode</th>
            <th>Transaction Type</th>
            <th>Service</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    </>
  )
}

export default PaymentPlatforms
