import React, { useState, useEffect } from "react"
import { Doughnut } from "react-chartjs-2"
import "chart.js/auto"
import { Card } from "react-bootstrap"
import { generateRandomColors } from "../../../helpers/randomColors"

const IncomePerHotspot = ({ data, hotspots, plans }) => {
  const [hotspotsData, setHotspotData] = useState([])

  const getPlan = (planId) => {
    return plans.find((p) => p.uuid === planId)
  }

  const getHotspot = (hotspotId) => {
    return hotspots.find((h) => h.id === Number(hotspotId))
  }

  useEffect(() => {
    const formatedHotspots = (data || []).reduce((accum, current) => {
      const plan = getPlan(current.plan_id)
      const hotspot = getHotspot(plan?.hotspot_id)
      if (plan && hotspot) {
        return {
          ...accum,
          [hotspot.name]: (accum[hotspot.name] || 0) + Number(current.amount),
        }
      }
      return accum // Always return the accumulator
    }, {})
    setHotspotData(formatedHotspots)
  }, [data, hotspots, plans])

  const colors = generateRandomColors(Object.keys(hotspotsData).length)

  const dataPie = {
    labels: Object.keys(hotspotsData),
    datasets: [
      {
        data: Object.values(hotspotsData),
        backgroundColor: colors,
      },
    ],
  }

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  }
  return (
    <Card className="ml-5">
      <Card.Header>
        <Card.Title as="h6">Total Income Per Hotspot in KES</Card.Title>
      </Card.Header>
      <Card.Body>
        <Doughnut data={dataPie} options={optionPie} className="ht-350" />
      </Card.Body>
    </Card>
  )
}

export default IncomePerHotspot
