import React from "react"

import { Form, Table, Button, Dropdown } from "react-bootstrap"
import IsLoading from "./IsLoading"
import DefaultPagination from "./DefaultPagination"

const MainGrid = ({
  columns,
  tableData,
  isSelectable,
  pagination,
  onSelect,
  error,
  isLoading,
  onPageChnage,
  selected,
  onRefresh,
  actions,
}) => {
  const canBeSelected = isSelectable || false
  const canBePaginated = pagination || false

  const handleSelect = (isSelected, row, rowId) => {
    onSelect(isSelected, row, rowId)
  }

  const headers = columns.map((column, index) => (
    <th key={index}>{column.description}</th>
  ))

  const rows = tableData.map((row) => (
    <tr key={row.id}>
      {canBeSelected && (
        <td>
          <Form.Check
            type="checkbox"
            checked={selected && selected.id === row.id}
            id={row.id}
            onChange={(e) => handleSelect(e.target.checked, row, row.id)}
          />
        </td>
      )}
      {columns.map((column, index) => (
        <td key={index}>{row[column.name]}</td>
      ))}
      <td>
        {actions && actions?.length > 0 && (
          <Dropdown drop={tableData.length <= 2 ? 'up' : 'down'}>
            <Dropdown.Toggle variant="secondary" size="sm">
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {(actions || []).map((action, index) => (
                <Dropdown.Item  key={index} onClick={() => action.handler(row)}>
                  {action.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
      {onRefresh && <td></td>}
    </tr>
  ))

  const display = () => {
    if (isLoading) {
      return <IsLoading />
    }else if(error && error.message) {
      return <h6>{error?.message || "error occured"}</h6>
    }else if(rows.length === 0){
      return  <h6 className="mt-4">No data found</h6>
    }
  }


  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            {canBeSelected && (
              <th>
                <Form.Check // prettier-ignore
                  type="checkbox"
                  disabled
                />
              </th>
            )}
            {headers}
            {actions && actions?.length > 0 && <th>Action</th>}
            {onRefresh && (
              <th>
                  <i onClick={onRefresh} className="ri-restart-fill tw-text-blue-950 tw-font-bold tw-text-2xl"></i>
              </th>
            )}
            
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </Table>
      {display()}
      {canBePaginated && (rows || []).length > 0 && (
        <DefaultPagination
          pagination={pagination}
          onPageChnage={onPageChnage}
        />
      )}
    </>
  )
}

export default MainGrid
