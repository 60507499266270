import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"
import ErrorMessage from "../../../../components/ErrorMessage"

// todo: fetch it from backend
const reasons = [
  {name: "cash", description: "Paid in cash"},
  {name: "mpesa_direct", description: "Paid via Mpesa directly to our account"}
]

const ActivateForm = ({ formik, error }) => {
  const reasonOptions = reasons.map(reason => {
    return (
      <option key={reason.name} value={reason.name}>
        {reason.description}
      </option>
    )
  })
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <ErrorMessage errors={error} />
          </Col>
        </Row>
        <Row>
        <Col>
            <Form.Group className="mb-3" controlId="reason">
              <Form.Label>Activation Reason</Form.Label>
              <Form.Select
                value={formik.values.reason}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="reason"
              >
                <option>Select reason</option>
                {reasonOptions}
              </Form.Select>
              {formik.errors.reason && formik.touched.reason && (
                <Form.Text className="text-danger">
                  {formik.errors.reason}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control
                type="text"
                placeholder="note/Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="description"
              />
              {formik.errors.description && formik.touched.description && (
                <Form.Text className="text-danger">
                  {formik.errors.description}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="amount"
              />
              {formik.errors.amount && formik.touched.amount && (
                <Form.Text className="text-danger">
                  {formik.errors.amount}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Reference (if cash, put your name)</Form.Label>
              <Form.Control
                type="text"
                placeholder="reference number"
                value={formik.values.reference_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="reference_number"
              />
              {formik.errors.reference_number && formik.touched.reference_number && (
                <Form.Text className="text-danger">
                  {formik.errors.reference_number}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ActivateForm
