import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Form, Card, Col, Row, Button } from "react-bootstrap"
import {
  useGetPackagesQuery,
  useCreatePackageMutation,
  useEditPackageMutation,
  useGetHotspotsQuery,
  useDeletePackageMutation
} from "./packageApiSlice"
import MainGrid from "../../../components/MainGrid"
import Dashboard from "../../../components/Dashboard"
import { formatime, notify } from "../../../helpers"
import CustomDialog from "../../../components/CustomDialog"
import PackageForm from "./PackageForm"
import './style.css'

const columns = [
  { name: "count", description: "#" },
  { name: "uuid", description: "Plan Id" },
  { name: "name", description: "Name" },
  { name: "duration", description: "Duration" },
  { name: "upload_speed", description: "Upload Speed" },
  { name: "download_speed", description: "Download Speed" },
  { name: "bundle_size", description: "Bunde Size" },
  { name: "price", description: "Price" },
  { name: "hotspot_id", description: "Hotspot Id" },
  { name: "hotspotname", description: "Hotspot Name" },
]

export default function Packages() {
  const [hotspotId, setHotspotId] = useState(null);
  const { data: plans = [], fetchError, isLoading,refetch } = useGetPackagesQuery(hotspotId)
  const { data: hotspots = [], isLoading: isHotspotLoading } = useGetHotspotsQuery()
  const [createPackage, { isLoading: isCreating }] = useCreatePackageMutation()
  const [editPackage, { isLoading: isEditing }] = useEditPackageMutation()
  const [deletePackage, { isLoading: isDeleting }] = useDeletePackageMutation()

  const [requireFetch, setRequireFetch] = useState(false)
  const [selected, setSelected] = useState(null)
  const [error, setError] = useState(null)
  const [createError, setCreateError] = useState(null)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Create Hotspot Plan")
  const [initialValues, setInitialValues] = useState({
    name: "",
    bundle_size:  "",
    price: "",
    duration: "",
    time_unit: "",
    hotspot_id: "",
    upload_speed: "",
    download_speed: ""
  })





  // modal logic
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      console.log(values, "is this")
      saveAndCLose(values, resetForm)
    },
  })
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Create Hotspot Plan")
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const plan = {
      bundle_size: Number(values.bundle_size),
      price: Number(values.price),
      duration: Number(values.duration),
      time_unit: values.time_unit,
      hotspot_id: Number(values.hotspot_id),
      name: values.name,
      download_speed: Number(values.download_speed),
      upload_speed: Number(values.upload_speed),
    }
    // do logic to save data
    if (action === "add") {
      try {
        await createPackage(plan).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        notify('plan added Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    } else {
      try {
        const editedPackage = await editPackage({id:selected.id, ...plan}).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        setInitialValues(editedPackage)
        notify('plan edited Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    }
  }

  // on mount, get list of packages
  useEffect(() => {
    if(requireFetch) {
      refetch()
    }
  }, [requireFetch])


  const handleOnChange = (e)=> {
    const value = Number(e.target.value)
    if(value === -2) {
      setHotspotId(null)
      setRequireFetch(true)

    }else if (value > 0 ) {
      setHotspotId(value)
      setRequireFetch(true)
    }
  }

  const handleDelete = async (row) => {
    try {
      await deletePackage(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Plan Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    const plan = (plans?.data || []).find(p => p.id === row.id)
    setShow(true)
    setAction("edit")
    setTitle("Edit Hotspot Plan")

    const values  = {
      name: plan.name,
      bundle_size: plan.bundle_size,
      price: plan.price,
      duration: plan.duration,
      time_unit: plan.time_unit,
      hotspot_id: plan.hotspot_id,
      upload_speed: plan.upload_speed,
      download_speed: plan.download_speed
    }
    setInitialValues(values)
    setSelected(plan)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]

  
  // columns
  let count = 0
  const formatedData = (plans?.data || []).map((plan) => {
    const hotspot = (hotspots?.data || []).find(h => h.id === plan.hotspot_id)
    count ++
    return {
      ...plan,
      count: count,
      upload_speed: `${plan.upload_speed} ${plan.speed_unit}`,
      download_speed: `${plan.download_speed} ${plan.speed_unit}`,
      price: `${plan.currency} ${Number(plan.price).toFixed(2)}`,
      duration: formatime(plan.duration, plan.time_unit),
      bundle_size: `${plan.bundle_size} GB`,
      hotspot_id: hotspot?.uuid,
      hotspotname: hotspot?.name
    }
  })

  const options = (hotspots?.data || []).map(h=> {
    return (
      <option key={h.id} value={h.id}>
     {h.name}
    </option>
    )
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading || isEditing || isHotspotLoading || isDeleting}
          error={error}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )
  return (
    <React.Fragment>
      <Dashboard title="Hotspot Plans">
        <Row className="package-page-header">
          <Col  lg={4} md={6} xs={12} className="filter-layout">
          <h5 className="filterByLabel">Filter By Hotspot: </h5>
          <div className="filter-by-container" xs={8}>
            <Form.Group>
              <Form.Select 
              value={hotspotId}
              onChange={handleOnChange}
              name="hotspot">
                <option key={-1} value={-1} >Select Hotspot</option>
                <option key={-2} value={-2} >All</option>
                {options}
              </Form.Select>
            </Form.Group>
          </div>
          </Col>
          <Col >
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Plan{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <Form method="post" onSubmit={formik.handleSubmit}>
        <CustomDialog
          show={show}
          title={title}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isCreating}
          size={'lg'}
          
        >
          <PackageForm formik={formik} error={createError} hotspots={hotspots} />
        </CustomDialog>
      </Form>
    </React.Fragment>
  )
}



// validation
const validationSchema = Yup.object({
  name: Yup.string().required("Plan name must be specified"),
  price: Yup.number().required("Price is required"),
  duration: Yup.number().required("duration is required"),
  time_unit: Yup.string().required("time unit must be specified"),
  hotspot_id: Yup.number().required("hotspot must be selected"),
  bundle_size: Yup.number().required("bundle is required"),
  upload_speed: Yup.number().required("Upload speed is required"),
  download_speed: Yup.number().required("donwload speed is required"),
})
