import { apiSlice } from "../../../app/api/apiSlice"

export const packageApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPppPackages: builder.query({
            query: ()=> `/api/isp/ppoe/plans`,
        }),
        createPppPackage: builder.mutation({
            query: plan => ({
                url: '/api/isp/ppoe/plans',
                method: 'POST',
                body: {
                    params: plan
                }
            })
        }),
        deletePppPackage: builder.mutation({
            query: plan_id => ({
                url:  `/api/isp/ppoe/plans/${plan_id}`,
                method: 'DELETE'
            })
        }),
        editPppPackage: builder.mutation({
            query: (plan) => {
                return {
                    url: `/api/isp/ppoe/plans/${plan.id}`,
                method: 'PUT',
                body: {
                    params: plan
                }
                }
            }
        })
    })
});

export const {
    useCreatePppPackageMutation, 
    useEditPppPackageMutation, 
    useGetPppPackagesQuery,
    useDeletePppPackageMutation
} = packageApiSlice;