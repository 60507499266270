import React from "react";


import HotspotPackages from "../pages/Hotspot/Plans";
import PppPackages from "../pages/PPP/Plans";

import Routers from "../pages/Configuration/Routers";
import Hotspots from "../pages/Configuration/Hotspots";
import Zones from "../pages/Configuration/Zones";
import HotspotTransactions from "../pages/Hotspot/Transactions";
import PPPTransactions from "../pages/PPP/Transactions";

import ResellerInfo from "../pages/Setting/ResellerInfo";
import HotspotCustomers from "../pages/Hotspot/Customers";
import PPPCustomers from "../pages/PPP/Customers";

import HotspotDashboard from "../pages/Dashboard/Hotspot";
import PPPDashboard from "../pages/Dashboard/PPP";

import Profile from "../pages/PPP/Customers/Profile";
import Billing from "../pages/Billing/index";
import AccessPoints from "../pages/Configuration/AccessPoints";

import AllTransferReport from "../pages/Reports/Transactions";

const protectedRoutes = [
// dashboard
{ path: "dashboard/hotspot/finance", element: <HotspotDashboard /> },
{ path: "dashboard/ppp/finance", element: <PPPDashboard /> },

// configurations
{ path: "isp/routers", element: <Routers /> },
{ path: "isp/hotspots", element: <Hotspots /> },
{ path: "isp/zones", element: <Zones /> },
{ path: "isp/access-points", element: <AccessPoints /> },

// hotspot
{ path: "isp/hotspot/plans", element: <HotspotPackages /> },
{ path: "isp/hotspot/customers", element: <HotspotCustomers /> },
{ path: "isp/hotspot/transactions", element: <HotspotTransactions /> },

// ppp
{ path: "isp/ppp/plans", element: <PppPackages /> },
{ path: "isp/ppp/customers", element: <PPPCustomers /> },
{ path: "isp/ppp/transactions", element: <PPPTransactions /> },
{ path: "isp/ppp/customer/profile/:customerId", element: <Profile /> },

// setting
{ path: "isp/info", element: <ResellerInfo /> },

// reports
{ path: "isp/transactions", element: <AllTransferReport /> },

// billing
{ path: "isp/billing", element: <Billing /> }
];

export default protectedRoutes;