import React from "react"
import CustomDialog from "../../../components/CustomDialog"
import { Form, Card, Row, Col, Alert } from "react-bootstrap"
import ErrorMessage from "../../../components/ErrorMessage"

const AccessPointForm = ({
  show,
  title,
  handleCloseDialog,
  formik,
  isLoading,
  errors
}) => {

  return (
    <CustomDialog
      show={show}
      title={title}
      onCLose={handleCloseDialog}
      onSave={formik.handleSubmit}
      isLoading={isLoading}
      size={"lg"}
    >
      <Form method="post" onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <ErrorMessage errors={errors} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>AP Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Access Point Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <Form.Text className="text-danger">
                      {formik.errors.name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>AP Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Access Point Type. i.e m2"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="type"
                  />
                  {formik.errors.type && formik.touched.type && (
                    <Form.Text className="text-danger">
                      {formik.errors.type}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Mac Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="mac address"
                    value={formik.values.mac_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="mac_address"
                  />
                  {formik.errors.mac_address && formik.touched.mac_address && (
                    <Form.Text className="text-danger">
                      {formik.errors.mac_address}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Landmark"
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="landmark"
                  />
                  {formik.errors.landmark && formik.touched.landmark && (
                    <Form.Text className="text-danger">
                      {formik.errors.landmark}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col>
                <Form.Group>
                  <Form.Label>Offline After (In minutes)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="After How long should AP be marked offline"
                    value={formik.values.offline_after}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="offline_after"
                  />
                  {formik.errors.offline_after && formik.touched.offline_after && (
                    <Form.Text className="text-danger">
                      {formik.errors.offline_after}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                  />
                  {formik.errors.description && formik.touched.description && (
                    <Form.Text className="text-danger">
                      {formik.errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </CustomDialog>
  )
}

export default AccessPointForm
