import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"
const PackageForm = ({ formik, error }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {error && error?.message && (
              <Alert variant="danger">{error?.message}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Upload Speed (Mbps)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Upload Speed"
                value={formik.values.upload_speed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="upload_speed"
              />
              {formik.errors.upload_speed && formik.touched.upload_speed && (
                <Form.Text className="text-danger">
                  {formik.errors.upload_speed}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Download Speed (Mbps)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Download Speed"
                value={formik.values.download_speed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="download_speed"
              />
              {formik.errors.download_speed && formik.touched.download_speed && (
                <Form.Text className="text-danger">
                  {formik.errors.download_speed}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="duration"
              />
              {formik.errors.duration && formik.touched.duration && (
                <Form.Text className="text-danger">
                  {formik.errors.duration}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Time Unit</Form.Label>
              <Form.Select
                value={formik.values.time_unit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="time_unit"
              >
                <option>Open this select menu</option>
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </Form.Select>
              {formik.errors.time_unit && formik.touched.time_unit  && (
                <Form.Text className="text-danger">
                  {formik.errors.time_unit}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="price"
              />
              {formik.errors.price && formik.touched.price && (
                <Form.Text className="text-danger">
                  {formik.errors.price}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Bundle Size in GB</Form.Label>
              <Form.Control
                type="text"
                placeholder="bundle_size"
                value={formik.values.bundle_size}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="bundle_size"
              />
              {formik.errors.bundle_size && formik.touched.bundle_size && (
                <Form.Text className="text-danger">
                  {formik.errors.bundle_size}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Internet Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
              />
              {formik.errors.name && formik.touched.name && (
                <Form.Text className="text-danger">
                  {formik.errors.name}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
         
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PackageForm
