import React from "react"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import Dashboard from "../../components/Dashboard"
import PendingBill from "./PendingBill"
import History from "./BillingHistory"
import { useGetBillingHistoryQuery } from "./billingApiSlice"
import IsLoading from "../../components/IsLoading"

const BillingHistory = () => {
  const {
    data: billingHistory,
    error: errors,
    isLoading,
    refetch,
  } = useGetBillingHistoryQuery()
  
  const content = isLoading ? <IsLoading /> : (
    <Row>
      <Col>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Pending Bills">
            <PendingBill data={billingHistory?.data} />
          </Tab>
          <Tab eventKey="hotspot" title="Billing History">
          <History data={billingHistory?.data} />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  )
  return (
    <>
      <Dashboard title={"Billing History"}>{content}</Dashboard>
    </>
  )
}

export default BillingHistory
