import React, { useState } from "react"
import { Table, Dropdown } from "react-bootstrap"
import { formatDate } from "../../../../helpers"
import AddSubscription from "../AddSubscription"
import ChangeCredentials from "../ChangeCredential"
import { useDeletePPPSubscriptionMutation, useDeactivatePPPUserMutation } from "../pppUsersApiSlice"
import { notify } from "../../../../helpers"
import ActivateSession from "../ActivateSession"

const formatSpeed = (plan) => {
  return `${plan.upload_speed}/${plan.download_speed} ${plan.speed_unit}`
}


const SubscriptionList = ({
  subscriptions,
  plans,
  zones,
  customer,
  handleRefetch,
  accounts,
}) => {
  const [deletePPPSubscription] = useDeletePPPSubscriptionMutation()
  const [deactivatePPPUser] = useDeactivatePPPUserMutation()
  const [showSubscriptionDiaLog, setShowSubscriptionDiaLog] = useState(false)
  const [showCredentialsDialog, setShowCredentialsDialog] = useState(false)
  const [showactivateDialog, setShowActivateDialog] = useState(false)
  const [defaultValues, setDefaultValues] = useState({})

  const handleEdit = (row) => {
    const account = accounts.find((account) => account.uuid === row.account_id)
    const subscription_data = {
      plan_id: row?.plan?.id,
      zone_id: row?.zone_id,
      landmark: row.landmark,
      house_number: row.house_number,
      credentials_exists: false,
      username: row.username,
      password: row.password,
      ext_account_number: account?.ext_account_number,
      use_external_account: false,
      id: row.id,
    }
    setDefaultValues(subscription_data)
    setShowSubscriptionDiaLog(true)
  }

  const handleChangeCredentials = (row) => {
    const data = {
      username: row.username,
      password: row.password,
      id: row.id
    }
    setDefaultValues(data)
    setShowCredentialsDialog(true)
  }

  const handleActivateSession = (row) => {
    setDefaultValues(row)
    setShowActivateDialog(true)
  }

  const handleDeleteSubsription = async (row) => {
    try {
      await deletePPPSubscription({customer_id: customer.uuid, id: row.id}).unwrap()
      handleRefetch()
        notify('Subscription Deleted Successfully', false)
    } catch (error) {
        notify('Failed. Please try again', true)
    }
  }

  const handleDeactivateSession = async (row) => {
    try {
      await deactivatePPPUser({customer_id: customer.uuid, id: row.id}).unwrap()
      handleRefetch()
        notify('Session Deactivated Successfully', false)
    } catch (error) {
        notify('Failed. Please try again', true)
    }
  }

  const popperConfig = {
    strategy: "fixed",
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'top'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport', // or 'scrollParent', 'window', or a specific element
        },
      },
    ],
  };

  const containerStyle = {
    overflow: "hidden",
  };

  const content = Array.isArray(subscriptions)
    ? subscriptions.map((sub, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{sub.username}</td>
            <td>{sub.status}</td>
            <td>{sub.downloaded_data}</td>
            <td>{sub.uploaded_data}</td>
            <td>{sub.total_data}</td>
            <td>{sub.used_time}</td>
            <td>{sub.zone_name}</td>
            <td>{sub.assigned_ip}</td>
            <td>{formatDate(sub.activated_at)}</td>
            <td>{formatDate(sub.expire_at)}</td>
            <td>{sub.landmark}</td>
            <td>{sub.house_number}</td>
            <td>{sub.plan.name}</td>
            <td>{`${sub.plan.currency} ${sub.plan.price}`}</td>
            <td>
              <Dropdown  style={{ position: "static" }}>
                <Dropdown.Toggle variant="secondary" size="sm">
                  Action
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={popperConfig}>
                  <Dropdown.Item onClick={() => handleEdit(sub)}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item disabled={sub.status === 'active'} onClick={()=> handleActivateSession(sub)}>Activate</Dropdown.Item>
                  <Dropdown.Item onClick={()=> handleChangeCredentials(sub)}>Change Credentials</Dropdown.Item>
                  <Dropdown.Item disabled={sub.status === 'inactive'} onClick={()=> handleDeactivateSession(sub)}>Deactivate</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDeleteSubsription(sub)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )
      })
    : []

  return (
    <>
      <Table responsive  style={containerStyle}>
        <thead>
          <tr>
            <th>#</th>
            <th>username</th>
            <th>status</th>
            <th>Downloaded Data</th>
            <th>Uploaded Data</th>
            <th>total Data</th>
            <th>Online Time</th>
            <th>Zone</th>
            <th>Ip Address</th>
            <th>Activated On</th>
            <th>Expires_At</th>
            <th>Landmark</th>
            <th>House Number</th>
            <th>Plan Name</th>
            <th>Plan Price</th>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>

      <AddSubscription
        plans={plans?.data || []}
        zones={zones?.data || []}
        show={showSubscriptionDiaLog}
        handleShow={setShowSubscriptionDiaLog}
        handleRefetch={handleRefetch}
        action={"edit_subscription"}
        customerId={customer.uuid}
        defaultValues={defaultValues}
      />

      <ChangeCredentials
        customerId={customer.uuid}
        subscription={defaultValues}
        show={showCredentialsDialog}
        handleShow={setShowCredentialsDialog}
        handleRefetch={handleRefetch}
      />
      <ActivateSession
        customerId={customer.uuid}
        subscription={defaultValues}
        show={showactivateDialog}
        handleShow={setShowActivateDialog}
        handleRefetch={handleRefetch}
      />
    </>
  )
}

export default SubscriptionList
