import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useNavigate } from "react-router-dom"
import {
  useListPPPUsersQuery,
  useDeletePPPUserMutation,
} from "./pppUsersApiSlice"
import { useGetPppPackagesQuery } from "../../../app/api/sharedApiSlice"
import { useGetZonesQuery } from "../../Configuration/Zones/zoneApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import CreateCustomer from "./CreateCustomer"
import AddSubscription from "./AddSubscription"
import { notify } from "../../../helpers"
import FilterByPhone from "../../../components/FilterByPhone"
import { formatDate } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "first_name", description: "Firstname" },
  { name: "last_name", description: "Lastname" },
  { name: "status", description: "Status" },
  { name: "email", description: "Email" },
  { name: "phone_number", description: "Phone" },
  { name: "inserted_at", description: "Joined On" },
  { name: "subscription_count", description: "No. Subscriptions" },
  { name: "downloaded_data", description: "Downloaded Data" },
  { name: "uploaded_data", description: "Uploaded Data" },
  { name: "total_data", description: "Total Used Data" },
]

const PPPUsers = () => {
  const navigate = useNavigate()
  const { data: plans } = useGetPppPackagesQuery()
  const { data: zones } = useGetZonesQuery()
  const [currentPage, setCurrentPage] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [filteredPhone, setFilterPhone] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [action, setAction] = useState(null)
  const [custDefault, setCustDefault] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const {
    data,
    error: listCustomersError,
    isLoading,
    refetch,
  } = useListPPPUsersQuery({
    pageSize: 100,
    pageNumber: currentPage,
    phoneNumber:
      phoneNumber === "" || phoneNumber == null ? phoneNumber : filteredPhone,
  })
  const [deletePPPUser, { isLoading: isDeleting }] = useDeletePPPUserMutation()
  // create user
  const [showCustomerDiaLog, setShowCustomerDiaLog] = useState(false)
  const [showSubscriptionDiaLog, setShowSubscriptionDiaLog] = useState(false)

  // handle search filter
  const handleSearch = () => {
    if (!phoneNumber) {
      setFilterPhone(null)
      return refetch()
    } else {
      setFilterPhone(phoneNumber)
      return refetch()
    }
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  // on page change
  useEffect(() => {
    refetch()
  }, [currentPage, refresh])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleCreateCustomer = () => {
    setAction("add_customer")
    setShowCustomerDiaLog(true)
  }

  const handleDeleteCustomer = async (row) => {
    try {
      await deletePPPUser(row.uuid).unwrap()
      refetch()
      notify("Customer Deleted Successfully", false)
    } catch (error) {
      notify("Failed. Please try again", true)
    }
  }

  const handleEditCustomer = (row) => {
    setAction("edit_customer")
    const customer = {
      email: row.email,
      phone_number: row.phone_number,
      first_name: row.first_name,
      last_name: row.last_name,
      uuid: row.uuid,
      id: row.id,
      username: row.username,
    }
    setCustDefault(customer)
    setShowCustomerDiaLog(true)
  }

  const handleViewProfile = (row) => {
    navigate(`/isp/ppp/customer/profile/${row.uuid}`)
  }

  const handleSubscriptionAdd = (row) => {
    setCustomerId(row.uuid)
    setShowSubscriptionDiaLog(true)
    console.log(row)
  }

  const actions = [
    { title: "Edit Customer", handler: handleEditCustomer },
    { title: "View Customer Details", handler: handleViewProfile },
    { title: "Add Subscription", handler: handleSubscriptionAdd },
    { title: "Delete Customer", handler: handleDeleteCustomer },
  ]

  const formatedData = Array.isArray(data?.customers)
    ? data?.customers?.map((user, index) => {
        return {
          ...user,
          count: index + 1,
          inserted_at: formatDate(user.inserted_at),
        }
      })
    : []

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isLoading={isLoading || isDeleting}
          error={listCustomersError}
          onPageChnage={handlePageChange}
          isSelectable={false}
          actions={actions}
          onRefresh={onRefresh}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next:
              currentPage < data?.total_pages
                ? currentPage + 1
                : data?.total_pages,
            previous: currentPage > 1 ? currentPage - 1 : 1,
            pagesTotal: data?.total_pages,
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"PPPoe Customers"}>
        <Row>
          <Col xs={12}>
            <div className="tw-flex tw-justify-between tw-w-full tw-mb-3">
              <FilterByPhone
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleSearch={handleSearch}
              />
              <div>
                <Button onClick={handleCreateCustomer} variant="primary">
                  Add Customer
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      {/* create customer */}
      <CreateCustomer
        show={showCustomerDiaLog}
        handleShow={setShowCustomerDiaLog}
        handleRefetch={setRefresh}
        action={action}
        defaultValues={custDefault}
      />

      {/* add subscription */}

      <AddSubscription
        plans={plans?.data || []}
        zones={zones?.data || []}
        show={showSubscriptionDiaLog}
        handleShow={setShowSubscriptionDiaLog}
        handleRefetch={setRefresh}
        action={"add_subscription"}
        customerId={customerId}
      />
    </>
  )
}
export default PPPUsers
