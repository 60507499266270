import { apiSlice } from "../../../app/api/apiSlice";

export const HotspotTransferReportAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHotspotTranferReport: builder.query({
            query: (params)=> {
                let url = '/api/isp/transactions?service_type=hotspot'
                if(params.pageSize) {
                    url = url + `&page_size=${params.pageSize}`
                }
                if(params.pageNumber) {
                    url = url + `&page_number=${params.pageNumber}`
                }
                return {
                    url
                }
            }
        }),

    })
});

export const { useGetHotspotTranferReportQuery } =  HotspotTransferReportAPiSlice;