import React, { useState, useEffect } from "react"
import { notify } from "../../../../helpers"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import {
  useCreatePPPUserMutation,
  useEditPPPUserMutation,
} from "../pppUsersApiSlice"
import CustomDialog from "../../../../components/CustomDialog"
import UserForm from "./UserForm"

const customer_data = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
}

const CreateCustomer = ({
  show,
  handleShow,
  handleRefetch,
  action,
  defaultValues,
}) => {
  const [createPPPUser, { isLoading }] = useCreatePPPUserMutation()
  const [editPPPUser, { isLoading: isEditing }] = useEditPPPUserMutation()
  const [errors, setErrors] = useState(null)
  const [initialValues] = useState(customer_data)
  const [operation, setOperation] = useState("Add PPPoe Customer")

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  useEffect(() => {
    if (action === "edit_customer") {
      formik.setValues(defaultValues)
      setOperation("Edit PPPoe Customer")
    } else {
      formik.setValues(customer_data)
      setOperation("Add PPPoe Customer")
    }
  }, [action])

  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const customer = {
      email: values.email,
      phone_number: values.phone_number,
      first_name: values.first_name,
      last_name: values.last_name,
    }
    // do logic to save data
    if (action === "add_customer") {
      try {
        await createPPPUser(customer).unwrap()
        resetForm()
        handleShow(false)
        setErrors(null)
        handleRefetch(true)
        notify("Customer added Successfuly", false)
      } catch (error) {
        setErrors(error?.data.errors || error?.data)
        notify("Failed. please try again", true)
      }
    } else {
      try {
        await editPPPUser({
          ...customer,
          uuid: defaultValues.uuid,
          username: defaultValues.username,
        }).unwrap()
        resetForm()
        handleShow(false)
        setErrors(null)
        handleRefetch(true)
        notify("Customer updated Successfuly", false)
      } catch (error) {
        setErrors(error?.data?.errors || error?.data)
        notify("Failed. please try again", true)
      }
    }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    setErrors(null)
    formik.resetForm()
  }

  return (
    <>
      <Form method="post" onSubmit={formik.handleSubmit} autoComplete="off">
        <CustomDialog
          show={show}
          title={operation}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isLoading || isEditing}
          size={"lg"}
        >
          <UserForm formik={formik} error={errors} clearErrors={setErrors} />
        </CustomDialog>
      </Form>
    </>
  )
}

// validation
const validationSchema = Yup.object({
  email: Yup.string()
    .required("email is required")
    .email("Invalid email format"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "invalid phone")
    .required("This field is required")
    .length(12, "invalid phone  format"),
  first_name: Yup.string().required("firstname is required"),
  last_name: Yup.string().required("firstname is required"),
})

export default CreateCustomer
