import React, { useState, useEffect } from "react"
import { Bar } from "react-chartjs-2"
import "chart.js/auto"
import { Card, Col, Row, Form } from "react-bootstrap"

const IncomePerPlan = ({ data, plans, hotspots }) => {
  const [hotspot, setHotspot] = useState("-1")
  const [plandata, setPlanData] = useState([])

  const handleOnChange = (e) => {
    setHotspot(e.target.value)
  }

  useEffect(() => {
    const planData = (data || []).reduce((accum, current) => {
      const plan = getPlan(current.plan_id)
      if (plan && (hotspot === "-1" || plan.hotspot_id == Number(hotspot))) {
        return {
          ...accum,
          [plan.name]: (accum[plan.name] || 0) + Number(current.amount),
        }
      }
      return accum // Always return the accumulator
    }, {})
    setPlanData(planData)
  }, [hotspot, data, plans, hotspots])

  const hotspotOptions = (hotspots || []).map((hotspot) => {
    return (
      <option key={hotspot.id} value={hotspot.id}>
        {hotspot.name}
      </option>
    )
  })

  const getPlan = (planId) => {
    return plans.find((p) => p.uuid === planId)
  }

  const dataBar = {
    labels: Object.keys(plandata),
    datasets: [
      {
        data: Object.values(plandata),
        backgroundColor: "#ffc107",
        barPercentage: 0.5,
      },
    ],
  }

  const optionBar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <Card className="mt-2 ">
      <Card.Header>
        <Card.Title as="h6">Income Per Plan in KES</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Select
                value={hotspot}
                onChange={handleOnChange}
                name="hotspot"
              >
                <option key={-1} value={-1}>
                  Select Hotspot
                </option>
                {hotspotOptions}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Bar data={dataBar} options={optionBar} className="ht-300" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default IncomePerPlan
