const dashboardMenu = [
  {
    label: "Hotspot Dashboard",
    link: "/dashboard/hotspot/finance",
    icon: "ri-dashboard-2-fill",
  },
  {
    label: "PPP Dashboard",
    link: "/dashboard/ppp/finance",
    icon: "ri-dashboard-3-fill",
  },
]

const configurationMenu = [
  {
    label: "Routers",
    link: "isp/routers",
    icon: "ri-router-line",
  },
  {
    label: "Hotspots",
    link: "isp/hotspots",
    icon: "ri-base-station-fill",
  },
  {
    label: "Zones",
    link: "isp/zones",
    icon: "ri-map-pin-line",
  },
  {
    label: "Access Points",
    link: "isp/access-points",
    icon: "ri-map-pin-line",
  },
]

const hotspotMenu = [
  {
    label: "Plans",
    link: "isp/hotspot/plans",
    icon: "ri-file-list-line",
  },
  {
    label: "Customers",
    link: "isp/hotspot/customers",
    icon: "ri-group-line",
  },
  {
    label: "Transactions",
    link: "isp/hotspot/transactions",
    icon: "ri-exchange-dollar-line",
  },
]

const pppMenu = [
  {
    label: "Plans",
    link: "isp/ppp/plans",
    icon: "ri-file-list-fill",
  },
  {
    label: "Customers",
    link: "isp/ppp/customers",
    icon: "ri-group-fill",
  },
  {
    label: "Transactions",
    link: "isp/ppp/transactions",
    icon: "ri-exchange-dollar-fill",
  },
]




const IspInfoMenus = [
  {
    label: "ISP Details",
    link: "isp/info",
    icon: "ri-account-pin-box-fill",
  },
]



const reportsMenu = [
  {
    label: "All Transactions",
    link: "isp/transactions",
    icon: "ri-money-cny-circle-fill",
  }
]

const billingMenu = [
  {
    label: "Billing History",
    link: "isp/billing",
    icon: "ri-money-dollar-circle-fill",
  },
]

export {
  dashboardMenu,
  configurationMenu,
  hotspotMenu,
  pppMenu,
  IspInfoMenus,
  reportsMenu,
  billingMenu,
}
