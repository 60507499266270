import { apiSlice } from "../../app/api/apiSlice"

const env = process.env.REACT_APP_ENV
const authUrl = env === 'uat' ? process.env.REACT_APP_UAT_AUTH_BASE_URL : process.env.REACT_APP_AUTH_BASE_URL

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${authUrl}/v1/api/auth/login`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${authUrl}/v1/api/logout`,
        method: "POST",
      }),
    }),
    getAccessToken: builder.mutation({
      query: (token) => ({
        url: `${authUrl}/v1/api/auth/token/renew`,
        method: "POST",
        body: {
          refresh_token: token,
        },
      }),
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation, useGetAccessTokenMutation } = authApiSlice
