import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetAllTranferReportQuery } from "./allTransferReportAPiSlice"
import { Col, Row, Card } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { formatTimeInZ } from "../../../helpers"

const columns = [
  { name: "id", description: "#" },
  { name: "status", description: "Payment Status" },
  { name: "amount", description: "Amount" },
  { name: "source", description: "Source" },
  { name: "destination", description: "Destination" },
  { name: "transferdatetime", description: "Transfer Date Time" },
  { name: "plan_id", description: "Plan Id" },
  { name: "customer_id", description: "Customer Id" },
  { name: "transaction_type", description: "Transfer Type" },
  { name: "description", description: "Description" },
];

const TransferReport = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useGetAllTranferReportQuery({ pageSize: 100, pageNumber: currentPage })

  //
  // on page change
  useEffect(()=> {
    refetch()
  }, [currentPage])


  const handlePageChange = (page) => {
    setCurrentPage(page)
  }



  const formatedData = (data?.transactions || []).map(tx => {
    return {
        ...tx, 
        amount: `${tx.currency} ${Number(tx.amount).toFixed(2)}`,
        transferdatetime: formatTimeInZ(tx.transferdatetime)
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading}
          error={error}
          onPageChnage = {handlePageChange}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next: currentPage < data?.total_pages ? currentPage + 1 : data?.total_pages, 
            previous: currentPage > 1 ? currentPage - 1 : 1, 
            pagesTotal: data?.total_pages
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"All Transactions"}>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default TransferReport
