import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap"
import bg1 from "../../../assets/img/bg1.jpg"
import { Logo } from "../../../components/Logo"
import { useDispatch } from "react-redux"
import { useLoginMutation } from "../authApiSlice"
import { setCredentials } from "../authSlice";

export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [login, {isLoading}] = useLoginMutation();

  // form validation
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      handleSubmit(values)
    }
  })
  const [disabled, setDisabled] = useState(false)
  const [buttonTitle, setButtonTitle] = useState("Sign In")
  const [errors, setErrors] = useState(null)

  const handleSubmit = async (values) => {
    const param = {
      email: values.username,
      password: values.password
    }
    try {
      setDisabled(true)
      setButtonTitle("Processing")
      const {data: loggeduser } = await login(param).unwrap();
      localStorage.setItem('ch-token', loggeduser.refresh_token);
      dispatch(setCredentials({...loggeduser}));
      navigate('/dashboard/hotspot/finance', {replace: true});
    } catch (error) {
      if(error?.data?.errors) {
        setButtonTitle("Sign In")
        setDisabled(false)
        setErrors({message: error?.data?.errors.message})
      }else {
      setButtonTitle("Sign In")
      setDisabled(false)
      setErrors( {message: 'Please Try again!'})
      }
    }

    //
  }

  const handleFormChange = (e) => {
    if(errors) {
      setErrors(null);
    }
  }

  // dont ask user to login when they are loggedin

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Logo />
              <Card.Title>Sign In</Card.Title>
              <Card.Text>Welcome back! Please signin to continue.</Card.Text>
              {errors && <Alert variant="danger">{errors.message}</Alert>}
            </Card.Header>
            <Card.Body>
              <Form method="post" onSubmit={formik.handleSubmit} onChange={handleFormChange}>
                <div className="mb-4">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email address"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="username"
                  />
                  {formik.errors.username && (
                    <Form.Text className="text-danger">
                      {formik.errors.username}
                    </Form.Text>
                  )}
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password <Link to="">Forgot password?</Link>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="password"
                  />
                  {formik.errors.password && (
                    <Form.Text className="text-danger">
                      {formik.errors.password}
                    </Form.Text>
                  )}
                </div>
                <Button disabled={disabled} type="submit" className="btn-sign">
                  {buttonTitle}
                </Button>
              </Form>
            </Card.Body>
            {/* <Card.Footer>
              Don't have an account?{" "}
              <Link to="/pages/signup2">Create an Account</Link>
            </Card.Footer> */}
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  )
}

const initialValues = {
  username: "",
  password: "",
}

const validationSchema = Yup.object({
  username: Yup.string().required("username is required"),
  password: Yup.string().required("password is required"),
})
