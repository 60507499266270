import { apiSlice } from "../../../app/api/apiSlice";

export const transferReportAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPppoeTranferReport: builder.query({
            query: (params)=> {
                let url = '/api/isp/transactions?service_type=ppoe'
                if(params.pageSize) {
                    url = url + `&page_size=${params.pageSize}`
                }
                if(params.pageNumber) {
                    url = url + `&page_number=${params.pageNumber}`
                }
                return {
                    url
                }
            }
        })

    })
});

export const { useGetPppoeTranferReportQuery } =  transferReportAPiSlice;