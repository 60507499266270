import React from "react"
import { Form, Button } from "react-bootstrap"

const FilterByPhone = ({ phoneNumber, setPhoneNumber, handleSearch }) => {
  return (
    <div className="tw-flex tw-items-center">
    <h5 className="filterByLabel">Filter By Phone: </h5>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e)=> setPhoneNumber(e.target.value) }
          name="phone_number"
        />
      </Form.Group>
      <Button onClick={handleSearch} className="tw-ml-3">Search</Button>
    </div>
  )
}

export default FilterByPhone
