import React from "react"
import { Table } from "react-bootstrap"

const Accounts = ({ accounts, subscriptions }) => {
  const content = Array.isArray(accounts)
    ? accounts.map((account, index) => {
      const subscription = subscriptions.find(sub => sub.account_id === account.uuid)
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{subscription?.username}</td>
            <td>{account.account_number}</td>
            <td>{account.ext_account_number}</td>
            <td>{account.status}</td>
            <td>{account.currency}</td>
          </tr>
        )
      })
    : []

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Username</th>
          <th>Internal Account</th>
          <th>External Account</th>
          <th>Status</th>
          <th>Currency</th>
        </tr>
      </thead>
      <tbody>{content}</tbody>
    </Table>
  )
}

export default Accounts
