import React from "react"
import { Table } from "react-bootstrap"

const Credentails = ({ subscriptions }) => {
  const content = Array.isArray(subscriptions)
    ? subscriptions.map((sub, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{sub.username}</td>
            <td><code>{sub.password}</code></td>
            <td>{sub?.plan?.pool_name}</td>
          </tr>
        )
      })
    : []

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Username</th>
          <th>Password</th>
          <th>Pool Name</th>
        </tr>
      </thead>
      <tbody>{content}</tbody>
    </Table>
  )
}

export default Credentails
