import React from "react"
import { Form, Card, Col, Row } from "react-bootstrap"
import ErrorMessage from "../../../../components/ErrorMessage"
const UserForm = ({ formik, error, clearErrors }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value)
    clearErrors(null)
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <ErrorMessage errors={error} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                type="text"
                placeholder="first name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="first_name"
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <Form.Text className="text-danger">
                  {formik.errors.first_name}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                type="text"
                placeholder="last name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="last_name"
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <Form.Text className="text-danger">
                  {formik.errors.last_name}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Username/Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                value={formik.values.email}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                name="email"
              />
              {formik.errors.email && formik.touched.email && (
                <Form.Text className="text-danger">
                  {formik.errors.email}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="254xxxxxxxx"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone_number"
              />
              {formik.errors.phone_number && formik.touched.phone_number && (
                <Form.Text className="text-danger">
                  {formik.errors.phone_number}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default UserForm
