import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import { 
  useCreateZoneMutation, 
  useDeleteZoneMutation, 
  useGetZonesQuery,
  useUpdateZoneMutation,
  useGetCountriesQuery
} from "./zoneApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import ZoneForm from "./ZoneForm"
import { notify } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "zone_id", description: "Id" },
  { name: "name", description: "Name" },
  { name: "status", description: "Status" },
  { name: "description", description: "Description" },
  { name: "capacity", description: "Capacity" },
  { name: "used_capacity", description: "Used Capacity" },
  { name: "remaining_capacity", description: "Remaining Capacity" },
  { name: "capacity_status", description: "Capacity Status" },
  { name: "coverage_area", description: "Coverage Area" },
  { name: "country_name", description: "Country Name" },
  { name: "county_name", description: "County Name" },
  { name: "installation_date", description: "Installation Date" },
  { name: "zone_type", description: "Zone Type" },
  { name: "longitude", description: "Longitude" },
  { name: "latitude", description: "Latitude" },

]

const Zones = () => {
  // state
  const {
    data,
    error: fetchedZoneErrors,
    isLoading: isFetching,
    refetch
  } = useGetZonesQuery()

  const {
    data: countries,
    error: fetchedcountriesErrors,
    isLoading: isFetchingCountries
  } = useGetCountriesQuery()

  const [createZone, { isLoading: isCreating }] = useCreateZoneMutation()
  const [updateZone, { isLoading: isEditing }] = useUpdateZoneMutation()
  const [deleteZone, { isLoading: isDeleting }] = useDeleteZoneMutation()

  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Zone")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    coverage_area: "",
    capacity: "",
    installation_date: "",
    country_id: "",
    county_id: "",
    zone_type: ""
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })


  // handlers
  const handleShowModalToAdd = () => {
    
    setShow(true)
    setAction("add")
    setTitle("Add Zone")
    setFormErrors(null)
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addZone = async (values, resetForm)=> {
    try {
      await createZone(values).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Zone added Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const editZone = async (values, resetForm)=> {
    try {
      await updateZone({...values, id: selected.id}).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Zone updated Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if(action === 'add') {
      await addZone(values, resetForm);
    }else {
      await editZone(values, resetForm);
    }
  }


  const handleDelete = async (row) => {
    try {
      await deleteZone(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Zone Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Zone")
    setFormErrors(null)
    const values  = {
      name: row.name,
      description: row.description,
      coverage_area: row.coverage_area,
      capacity: row.capacity,
      installation_date: row.installation_date,
      country_id: row.country_id,
      county_id: row.county_id,
      zone_type: row.zone_type
    }
    setInitialValues(values)
    setSelected(row)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]

  // on state changes
  useEffect(() => {
    if(isCreating || isEditing) {
      setInprogress(true)
    }else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])


  const zoneData = (data?.data || []).map((zone, index) => {
    return {
      ...zone,
      count: index + 1
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={zoneData}
          isSelectable={false}
          isLoading={isFetching}
          error={fetchedZoneErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Zones"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Zone{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <ZoneForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
        countries={countries?.data}
      />
    </>
  )
}

export default Zones



// validation
const validationSchema = Yup.object({
  name: Yup.string().required("name is required"),
  description: Yup.string().required("description is required"),
  coverage_area: Yup.number().optional(),
  capacity: Yup.number().required("Zone capacity is required"),
  installation_date: Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, "Installation date must be in the format yyyy-mm-dd").required("Installation date is required"),
  country_id: Yup.number().required("Country must be selected"),
  county_id: Yup.number().required("County must be selected"),
  zone_type: Yup.string().required("description is required")
})
