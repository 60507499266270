import React, { useEffect, useState } from "react"
import Dashboard from "../../../components/Dashboard"
import { Row, Col, Table, Card } from "react-bootstrap"
import Prism from "prismjs"
import SummaryCard from "../SummaryCard"
import { formatMoney } from "../../../helpers"
import IncomePerMonth from "./incomePerMonth"
import {
  usePppGeneralDashboardStatsQuery,
  usePppSubsExpiringTodayQuery,
  usePppIncomePerMonthQuery,
  usePppIncomePerPlanQuery
} from "./dashboardApiSlice"
import FilterByYear from "../../../components/FilterByYear"

import { useGetPppPackagesQuery } from "../../PPP/Plans/packageApiSlice"
import IncomePerPlan from "./IncomePerPlan"

const date = new Date()
const startYear = 2023
const currentYear = date.getFullYear()
const filteryears = []

for (let x = startYear; x <= currentYear; x++) {
  filteryears.push(x)
}

const PPPDashboard = () => {
  const [year, setYear] = useState(currentYear)
  const { data, isLoading, isError } = usePppGeneralDashboardStatsQuery(year)
  const { data: monthlyIncome = {} } = usePppIncomePerMonthQuery(year)
  const { data: expiringSubs = {} } = usePppSubsExpiringTodayQuery()
  const { data: incomePerPlan = {} } = usePppIncomePerPlanQuery(year)
  const { data: plans = {} } = useGetPppPackagesQuery()

  useEffect(() => {
    Prism.highlightAll()
  }, [])

  const handleYearOnChange = (e) => {
    const value = Number(e.target.value)
    if (value === 1) {
      setYear(currentYear)
    } else {
      setYear(value)
    }
  }

  const generalData = data?.data
  const totalUsers = generalData?.total_subscriptions || 0
  const expiredUsers = generalData?.expired_subscriptions || 0
  const activeUsers = generalData?.active_subscriptions || 0
  const incomeThisMonth = generalData?.income_this_month || 0
  const incomeToday = generalData?.income_today || 0

  const listOfExpiredUsers = Array.isArray(expiringSubs?.data) ? expiringSubs?.data.map((user) => {
    return (
      <tr key={user?.uuid}>
        <th scope="row">{user?.username}</th>
        <td>{`${user?.first_name || ""} ${user?.last_name || ""}`}</td>
        <td>{user?.plan_name}</td>
        <td>{`${new Date(user?.expires_at).toLocaleString()}`}</td>
        <td>{user?.phone_number}</td>
      </tr>
    )
  }) : <p>No data</p>

  const options = filteryears.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    )
  })

  return (
    <Dashboard title={"PPP Dasboard"}>
      <FilterByYear
        options={options}
        value={year}
        handleYearOnChange={handleYearOnChange}
      />
      <Row>
        <Col xs="12" md={12} lg={7}>
          <Row>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Total Users"}
                color={"text-dark"}
                value={totalUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Expired Users"}
                color={"text-primary"}
                value={expiredUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Active Users"}
                color={"text-secondary"}
                value={activeUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Income This Month"}
                color={"text-info"}
                value={formatMoney(incomeThisMonth)}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Income Today"}
                color={"text-warning"}
                value={formatMoney(incomeToday)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <IncomePerMonth data={monthlyIncome.data || []} />
            </Col>
          </Row>
        </Col>
        <Col xs="12" md={12} lg={5}>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <h6>Subscriptions Expiring Today</h6>
                </Card.Header>
                <Card.Body>
                  <Table hover responsive className="mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Name</th>
                        <th scope="col">Plan Name</th>
                        <th scope="col">Expired In </th>
                        <th scope="col">Phone</th>
                      </tr>
                    </thead>
                    <tbody>{listOfExpiredUsers}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
             <IncomePerPlan plans={plans?.data || []} data={incomePerPlan?.data || []} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Dashboard>
  )
}

export default PPPDashboard
